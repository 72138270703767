#secondary-nav { background: #fff; border-top: 0; border-bottom: 1px solid #E6EAEE; color: #000; }
/*#secondary-nav { -webkit-box-shadow: 0 0 5px #e8e8e8; -moz-box-shadow: 0 0 5px #e8e8e8; box-shadow: 0 0 5px #e8e8e8; }*/
#secondary-nav .nav { margin-bottom: 0; }
#secondary-nav .nav > li > a { border-bottom: 2px solid #fff; color: #7F8FA4; font-size: 14px; margin: 0 30px 0 0; padding: 15px 0 13px 0; text-transform: none; text-align: left; }
#secondary-nav .nav.pull-right a { margin: 0 0 0 30px; }
#secondary-nav .nav > li > a.active, #secondary-nav .nav > li > a.active:hover { border-bottom: 2px solid #1A91EB; color: #1A91EB; }
#secondary-nav .nav > li.dropdown { margin-right: 30px; }
#secondary-nav .nav > li.dropdown > a { margin: 0; }
#secondary-nav .nav > li.dropdown.open > a { background: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
#secondary-nav .close { color: #000; font-size: 20px; }
#secondary-nav .close span { background: #ccc; border: 1px solid #999; color: #000; padding: 0 8px; font-size: 20px; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; }
#secondary-nav .dropdown > a { color: #000; text-transform: none; }
#secondary-nav .navbar-nav > .dropdown.active { border-bottom: 2px solid #1A91EB; }
#secondary-nav .navbar-nav > .dropdown.active,
#secondary-nav .navbar-nav > .dropdown.active > a,
#secondary-nav .navbar-nav > .dropdown.active > a:hover,
#secondary-nav .navbar-nav > .dropdown.active > a:focus { background: transparent none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
#secondary-nav-toggle { background: transparent; border: none; cursor: pointer; float: none; top: 0; margin: 0 auto; position: inherit; width: 100%; }
#secondary-nav-collapsible { padding: 0; }

@media screen and (max-width: 1200px) {
    #secondary-nav-collapsible { border-top: 1px solid #e7e7e7; }
    #secondary-nav { padding: 10px 15px; }
    #secondary-nav .nav { margin: 2px 0 0 0; }
    #secondary-nav .nav > li > a { border-bottom: none; margin-right: 0; padding: 10px 15px; text-align: center; }
    #secondary-nav .nav > li > a.active, #secondary-nav .nav > li > a.active:hover { border-bottom: none; }
    #secondary-nav .nav > li.dropdown { margin-right: 0; }
    #secondary-nav .navbar-nav > .dropdown.active { border-bottom: none; }
    #secondary-nav .divider { border-bottom: 1px solid #E6EAEE; margin-bottom: 13px; padding-bottom: 8px; }
    #secondary-nav .divider a { display: none; }
    #secondary-nav .nav.pull-right { display: block; float: none; width: 100%; }
    #secondary-nav .nav.pull-right a { margin: 0 0 0 0; }
    #secondary-nav .nav a.close { float: none; padding: 0; margin: 20px 0; }
    #secondary-nav-collapsible > ul { padding-top: 8px; }
    .navbar-collapse.collapsing { margin-top: 10px; }
    .navbar-collapse.in { margin-top: 10px; }
}

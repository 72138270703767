/* Modal Dialog */

.modal-dialog { margin: 150px auto; }
.modal-header { background: #FAFBFC; border-bottom: 1px solid #E6EAEE; color: #fff; text-align: center; padding: 25px 30px; -webkit-border-radius: 5px 5px 0 0; -moz-border-radius: 5px 5px 0 0; border-radius: 5px 5px 0 0; }
.modal-header { padding: 15px 30px; }
.modal-header h3 { color: #000; border-bottom: 0; font-size: 20px; margin: 15px 0 0 0; padding: 0; }
.modal-header h4 { border-bottom: 0; margin-bottom: 0; padding: 0; }
.modal-footer { background: #FAFBFC; border-top: 1px solid #E6EAEE; text-align: right; padding: 15px 30px; }
.modal-body { background: #fff; padding: 40px 30px 40px 30px; }
.modal-body { max-height: 430px; overflow-y: auto; }
.modal-body .form-group:last-child { margin-bottom: 30px; }
.modal-body .panel-body > .form-group:last-child { margin-bottom: 0; }
/*.modal-body .panel-body .row > div > .form-group { margin-bottom: 0; }*/
.modal-body .panel-table td > .form-group:last-child { margin-bottom: 0; }
.modal-body > :last-child { margin-bottom: 0; }

@media screen and (max-width: 992px) {
    .modal-body .form-group:last-child { margin-bottom: 30px; }
}

/* Modal Dialog Action */

.modal-dialog-action { }
.modal-dialog-action .modal-dialog { max-width: 500px; }
.modal-dialog-action .modal-content { margin-left: 10px; margin-right: 10px; }
.modal-dialog-action .modal-body { padding: 30px 30px 30px 30px; text-align: center; }
.modal-dialog-action .modal-footer { background: transparent; border-top: 0; text-align: center; padding-top: 0; padding-bottom: 30px; }
.modal-dialog-action .modal-footer button, .modal-dialog-action .modal-footer a.btn { margin: 0 7px; min-width: 110px; }
.modal-dialog-action table { text-align: left; }

.modal-dialog-action.lg .modal-dialog, .modal-dialog-action.big .modal-dialog { max-width: 1000px; width: auto; }
.modal-dialog-action.lg .modal-body, .modal-dialog-action.big .modal-body { overflow-y: unset; max-height: unset; }

.modal-dialog-action.md .modal-dialog { max-width: 700px; width: auto; }
.modal-dialog-action.md .modal-body { overflow-y: unset; max-height: unset; }

.modal-dialog-action.auto-height { overflow-y: unset; max-height: unset; }

.modal-dialog-action.modern .modal-header { text-align: left; }
.modal-dialog-action.modern .modal-body { text-align: left; }
.modal-dialog-action.modern .modal-footer > * { float: right; }
.modal-dialog-action.modern .modal-footer > *:first-child { margin-right: 0; }

/* Alert  */

.modal-alert .modal-body { overflow-y: unset; }
.modal-alert .modal-body .form-group:last-child { margin-bottom: 15px; }
.modal-alert .modal-body > .form-group:last-child, .modal-alert .modal-body > .row:last-child > .form-group { margin-bottom: 0; }
.modal-alert .modal-body > .input-text { text-align: center; }
.modal-alert .modal-body .form-group:last-child { margin-bottom: 15px; }

/* Confirm  */

.modal-confirm .modal-body { overflow-y: unset; }
.modal-confirm .modal-body .form-group:last-child { margin-bottom: 15px; }
.modal-confirm .modal-body > .form-group:last-child, .modal-confirm .modal-body > .row:last-child > .form-group { margin-bottom: 0; }
.modal-confirm .modal-body > .input-text { text-align: center; }
.modal-confirm .input-group-addon { padding-left: 11px; padding-right: 11px; }
.modal-confirm .input-date-picker { min-width: 100%;width: 100%; }
.modal-confirm .react-datepicker-wrapper, .modal-confirm .react-datepicker__input-container { display: block; }

@media screen and (max-width: 413px) {
    .modal-dialog-action .modal-footer button, .modal-dialog-action .modal-footer a.btn { display: block; margin-bottom: 20px; width: 100%; }
}

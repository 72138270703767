
/* Nav Bar */

.navbar-toggle { background: #1A91EB; margin: 0; top: 30px; }
.navbar-toggle .icon-bar { background: #fff; }
.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand { margin-left: -5px; }
.navbar { border-left: 0; border-right: 0; box-shadow: none; -webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0; }
.navbar-nav > li > .dropdown-menu { -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; }
.navbar-nav > li > .dropdown-menu a { text-transform: uppercase; }

@media screen and (max-width: 1200px) {
    .navbar-nav .open .dropdown-menu { left: 0; transform: none; width: 100%; }
    .navbar-nav .open .dropdown-menu > li > a { padding: 5px 15px 5px 15px; text-align: center; }
}

@media screen and (max-width: 1200px) {
    .navbar-header { float: none; }
    .navbar-left, .navbar-right { float: none !important; }
    .navbar-toggle { display: block; }
    .navbar-collapse.collapse { display: none !important; }
    .navbar-nav { float: none !important; }
    .navbar-nav > li { float: none; }
    .collapse.in { display: block !important; }
}

/* Nav Tabs */

.nav-tabs { text-align: center; }
.nav-tabs > li { float: none; display: inline-block;  *display: inline; zoom: 1; }
.nav-tabs > li > a { background: #FAFBFC; border-bottom: 1px solid #ddd; font-size: 12px; margin-left: 5px; margin-right: 5px; text-transform: uppercase; }
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus { background: #FAFBFC; }

@media screen and (max-width: 1200px) {
    .nav-tabs { border-bottom: 0; }
    .nav-tabs > li { display: block; width: 100%; }
    .nav-tabs > li > a { border: 1px solid #ddd; -webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0; padding: 15px 15px; text-align: center; }
    .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus { background: #fff; border: 1px solid #ddd; }
}

.theme-layout-default .layout--showcase-5 {
    padding: 80px 0;
}

.theme-layout-default .layout--showcase-5 .primary .ce-block__content > * {
    text-align: center !important;
}

/* Header */

.theme-layout-default .layout--showcase-5 .primary .ce-block:nth-child(1) .ce-block__content > .ce-header,
.theme-layout-default .layout--showcase-5 .primary .ce-block:nth-child(2) .ce-block__content > .ce-header {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.theme-layout-default .layout--showcase-5 .primary .ce-block:nth-child(2) .ce-block__content > .ce-header {
    margin-bottom: 50px;
}

/* Description */

.theme-layout-default .layout--showcase-5 .primary .ce-block:nth-child(1) .ce-block__content > .ce-paragraph,
.theme-layout-default .layout--showcase-5 .primary .ce-block:nth-child(2) .ce-block__content > .ce-paragraph {
    color: #919dab;
    font-family: "Poppins", arial, sans-serif;
    font-size: 18px;
    line-height: 28px;
    margin: 30px 50px 50px;
}

.theme-layout-default .layout--showcase-5 .primary .ce-block:nth-child(1) .ce-block__content > .ce-paragraph {
    margin-top: 0;
    margin-bottom: 10px;
}

/* Features */

.theme-layout-default .layout--showcase-5 .form-group-features .features {
    margin-bottom: 0 !important;
}

.theme-layout-default .layout--showcase-5 .form-group-feature {
    text-align: center;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.theme-layout-default .layout--showcase-5 .feature .ce-block:last-child .ce-block__content {
    padding-bottom: 30px !important;
}

/* Features - Image */

.theme-layout-default .layout--showcase-5 .feature .binary-images-with-upload .image-container,
.theme-layout-default .layout--showcase-5 .feature .binary-images-with-upload .add {
    height: 300px;
}

.theme-layout-default .layout--showcase-5 .feature .ce-images .binary-images-with-upload {
    min-height: 305px;
}

.theme-layout-default .layout--showcase-5 .feature .binary-images-with-upload .image-container img {
    object-fit: cover;
}

/* Features - Header */

.theme-layout-default .layout--showcase-5 .feature .ce-block:nth-child(2) .ce-block__content {
    margin-top: 30px !important;
}

.theme-layout-default .layout--showcase-5 .feature .ce-block:nth-child(2) .ce-block__content > .ce-paragraph {
    font-family: "Poppins", arial, sans-serif;
}

/* Features - Supporting Content */

.theme-layout-default .layout--showcase-5 .feature .ce-block:nth-child(3) .ce-block__content > .ce-paragraph {
    color: #919dab !important;
    font-family: "Poppins", arial, sans-serif;
    font-size: 15px;
}

/* Features - Supporting Content */

.theme-layout-default .layout--showcase-5 .feature .ce-block .ce-block__content > .ce-paragraph {

}


/* Lead Out */

.theme-layout-default .layout--showcase-5 .lead-out .ce-block__content > * {
    text-align: center !important;
}
/* Field */

.input-checkboxes-picker {
    background: url('../images/select-arrow.png') no-repeat 100% 50% #fff;
    cursor: pointer;
    max-width: 170px;
}

.input-checkboxes-picker-wrap {
    position: relative;
}

.input-checkboxes-picker-wrap > input {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.input-checkboxes-picker-wrap > input:not(:last-child) {
    -moz-border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

/* Picker - Values */

.input-checkboxes-picker-wrap .values {
    position: absolute;
    top: 8px;
    left: 5px;
    overflow-x: auto;
    white-space: nowrap;
    width: calc(100% - 50px);
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.input-checkboxes-picker-wrap .values::-webkit-scrollbar {
    display: none;
}

.input-checkboxes-picker-wrap .values .value {
    background: #1A91EB;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-left: 5px;
    line-height: 1em;
    padding: 5px 30px 5px 10px;
    position: relative;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.input-checkboxes-picker-wrap .values .value:last-child {
}

.input-checkboxes-picker-wrap .values .value svg {
    cursor: pointer;
    color: #fff;
    fill: currentColor;
    stroke: currentColor;
    line-height: 1;
    stroke-width: 0;
    position: absolute;
    top: 4px;
    right: 8px;
    height: 17px;
    width: 17px;
    margin-left: 8px;
}

.input-checkboxes-picker-wrap .values .value:hover svg {
    color: #fff;
}

/* Picker - Container */

.input-checkboxes-picker-tooltip-values.bottom {
    margin-top: -6px;
    width: 100%;
    opacity: 1.0;
}

.input-checkboxes-picker-tooltip-values.bottom .tooltip-arrow {
    border-bottom-color: #DFE6F4;
    display: none;
}

.input-checkboxes-picker-tooltip-values.bottom .tooltip-inner {
    background: #fff;
    border: 1px solid #DFE6F4;
    color: #000;
    width: 250px;
    max-width: 250px;
    padding: 0;
    text-align: left;
    max-height: 232px;
    overflow: auto;
    -moz-border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    -webkit-box-shadow: 0 5px 20px #f0f0f0;
    -moz-box-shadow: 0 5px 20px #f0f0f0;
    box-shadow: 0 5px 20px #f0f0f0;
}

.has-warning .input-checkboxes-picker-tooltip-values.bottom .tooltip-inner {
    border-color: #1A91EB;
}

.input-checkboxes-picker-tooltip-values.bottom .tooltip-inner {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

/* Picker - Checkboxes */

.input-checkboxes-picker-tooltip-values.bottom .form-group {
    margin: 0;
}

.input-checkboxes-picker-tooltip-values.bottom .checkbox {
    border-bottom: 1px solid #E6EAEE;
    margin: 0;
}

.form-inline .input-checkboxes-picker-tooltip-values.bottom .form-group-input-checkboxes {
    display: block;
}

.form-inline .input-checkboxes-picker-tooltip-values.bottom .checkbox {
    display: block;
}

.input-checkboxes-picker-tooltip-values.bottom .checkbox:first-child {
}

.input-checkboxes-picker-tooltip-values.bottom .checkbox:last-child {
    border-radius: 0 0 4px 4px;
}

.input-checkboxes-picker-tooltip-values.bottom .checkbox:hover {
    background: #FAFBFC;
}

.input-checkboxes-picker-tooltip-values.bottom .checkbox:last-child {
    border-bottom-width: 0;
    margin-bottom: 0;
}

.input-checkboxes-picker-tooltip-values.bottom .checkbox input {
    position: unset;
    margin: 2px 10px 0 0;
}

.input-checkboxes-picker-tooltip-values.bottom .checkbox > label {
    cursor: pointer;
    display: block;
    margin: 0;
    padding: 8px 10px 8px 10px
}

.input-checkboxes-picker-tooltip-values.bottom .checkbox > label > span {
    /*margin-left: 10px;*/
}
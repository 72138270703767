/* Placeholder */

::-webkit-input-placeholder { color: #999; }
input:focus::-webkit-input-placeholder { color: #999; }
:-moz-placeholder { color: #999; opacity: 1; }
::-moz-placeholder { color: #999; opacity: 1; }
:-ms-input-placeholder { color: #999; }

.form-control::-webkit-input-placeholder { color: #999; }
input.form-control:focus::-webkit-input-placeholder { color: #999; }
.form-control:-moz-placeholder { color: #999; opacity: 1; }
.form-control::-moz-placeholder { color: #999; opacity: 1; }
.form-control:-ms-input-placeholder { color: #999; }
.input-select-enhanced-container > .input-select-enhanced .__placeholder { color: #999; }

/* Contacts Autofill */

input::-webkit-contacts-auto-fill-button { display: none !important; pointer-events: none; position: absolute; right: 0; visibility: hidden; }

/* Form Group */

.form-group { margin: 0 0 30px 0; }
.form-group.form-group-group { margin-bottom: 0; }
.form-group.form-group-margin-none { margin: 0 !important; }
td .form-group { position: unset; }
.row > .form-group { margin: 0 0 20px 0; }

.form-group > label { color: #434a54; font-size: 11px; font-weight: 500; opacity: 1; text-transform: uppercase; }

.help-block { color: #7F8FA4; margin-top: 0; }
.help-block.description { margin-bottom: 15px; }
.help-block.error { background: #c00; color: #fff; display: inline-block; margin-top: 10px; padding: 2px 7px; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; }

.field-side-info { color: #999; margin: 8px 0 0 0; }
.field-side-info .glyphicon { margin-right: 5px; }
.form-control { border: 1px solid #E6EAEE; height: 40px; padding: 12px 12px; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.form-control:focus { border: 1px solid #E6EAEE; -webkit-box-shadow: 0 0 8px #E6EAEE; }
.form-control.transparent { background: transparent; border-color: transparent; }

.input-group-addon { border-color: #E6EAEE; }
.input-group-addon { background: #fff; }
.input-group-addon > .fa { font-size: 11px; margin-bottom: 1px; }
.form-group.disabled .input-group-addon {background: #f6f6f6;}

input:focus::-webkit-textfield-decoration-container { visibility: hidden; }
input[type="text"].form-control, input[type="password"].form-control, textarea.form-control, select.form-control { color: #1A91EB; }
input[type="text"].form-control[disabled], input[type="password"].form-control[disabled], textarea.form-control[disabled], select.form-control[disabled] { background-color: #f6f6f6; color: #999; }
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control { background-color: #f6f6f6; color: #999; }
.form-control[disabled].transparent, .form-control[readonly].transparent, fieldset[disabled] .form-control.transparent { border-color: transparent; background-color: transparent; color: #555555; }
.form-control.big, .form-control.lg { font-size: 25px; height: auto; padding-top: 10px; padding-bottom: 10px; }
.form-control.uppercase { text-transform: uppercase; }

textarea.form-control { height: 200px; resize: vertical; }

/* Field Warning */

.has-warning .control-label { color: #1A91EB; }
.has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label { color: #56618F; }
.has-warning .help-block { color: #7F8FA4; }
.has-warning .help-block.error { color: #fff; }
.has-warning .form-control { border-color: #1A91EB; }
.has-warning .form-control:focus { border-color: #1A91EB; box-shadow: 0 0 8px rgba(80, 193, 233, 0.6); outline: none; }
.has-warning .input-group-addon { background: #fff; border-color: #1A91EB; }

/* Field Error */

.has-error .form-control, .has-error .input-group > .input-group-addon { border-color: #FF3E55; }
.has-error .form-control:focus { -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; }
.has-error .help-block { color: #7F8FA4; }
.help-block.error { color: #FF3E55 !important; display: block; background: transparent; font-size: 11px; font-weight: 400; padding: 0; text-align: right; text-transform: uppercase; }

/* Field Read Only */

.form-group .read-only .blank { color: #ccc; }

/* Inline Form */

.form-inline .input-group, .form-inline input[type="text"].form-control, .form-inline input[type="password"].form-control, .form-inline textarea.form-control, .form-inline .input-select-enhanced-container { margin-right: 5px; max-width: 180px; }
.form-inline .input-month-year-picker-wrap { margin-right: 5px; }
.form-inline .input-group, .form-inline input[type="text"].form-control, .form-inline input[type="password"].form-control, .form-inline textarea.form-control, .form-inline .input-select-enhanced-container { width: 180px; }
.form-inline .input-group { max-width: unset; }
.form-inline .input-group > input.form-control, .form-inline .input-group > select.form-control { margin-right: 0; }
.form-inline .input-group .input-group-addon { width: 1%; }
.form-inline .actions { margin: 0; }
.form-inline .pull-right .input-group, .form-inline .pull-right input[type="text"].form-control, .form-inline .pull-right input[type="password"].form-control, .form-inline .pull-right textarea.form-control, .form-inline .pull-right select.form-control, .form-inline .pull-right .input-select-enhanced-container { margin-left: 5px; margin-right: 0; }
.form-inline button, .form-inline a.btn { display: block; margin-top: -1px; /*min-width: 100px;*/ }
.form-inline .input-rich-text button, .form-inline .input-rich-text a.btn { width: auto; min-width: unset; }
.form-inline .actions button, .form-inline .actions a.btn { display: inline; margin-right: 0; }
.form-inline .pull-right .btn { margin-left: 5px; margin-right: 0; }

@media screen and (max-width: 992px) {
    .form-inline > .pull-right { float: none!important; }
}

@media screen and (max-width: 1200px) {
    .form-inline .form-group { display: block; margin-bottom: 15px; vertical-align: middle; }
    .form-inline .form-group { width: 100%; max-width: 100%; }
    .form-inline .form-control { display: block; vertical-align: middle; }
    .form-inline .input-group, .form-inline input[type="text"].form-control, .form-inline input[type="password"].form-control, .form-inline textarea.form-control, .form-inline .react-datepicker-wrapper, .form-inline .react-datepicker__input-container, .form-inline .input-month-year-picker-wrap, .form-inline select.form-control, .form-inline .input-select-enhanced-container { width: 100%; max-width: 100%; }
    .form-inline .input-month-year-picker-wrap { margin-right: 0; }
    .form-inline .actions { display: block; margin-bottom: 60px; }
    .form-inline .actions button, .form-inline .actions a.btn { display: block; margin-bottom: 15px; width: 100%; }
}

@media screen and (min-width: 1201px) {
    .form-inline .input-group-wide, .form-inline input[type="text"].form-control.wide, .form-inline input[type="password"].form-control.wide, .form-inline textarea.form-control.wide, .form-inline .form-group-wide .react-datepicker-wrapper input, .form-inline select.form-control.wide, .form-inline .form-group-wide .input-select-enhanced-container { width: 270px; max-width: 270px; }
}

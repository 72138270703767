.theme-layout-default .layout--content-28 {
    padding: 80px 0;
}

.theme-layout-default .layout--content-28 .primary .ce-block__content > * {
    text-align: center !important;
}

/* Tagline */

.theme-layout-default .layout--content-28 .primary .ce-block:nth-child(1) .ce-block__content > .ce-paragraph,
.theme-layout-default .layout--content-28 .primary .ce-block:nth-child(2) .ce-block__content > .ce-paragraph {
    color: #919dab;
    font-family: "Poppins", arial, sans-serif;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-left: 50px;
    margin-right: 50px;
}

.theme-layout-default .layout--content-28 .primary .ce-block:nth-child(2) .ce-block__content > .ce-paragraph {
    margin-bottom: 50px;
}

/* Header */

.theme-layout-default .layout--content-28 .primary .ce-block:nth-child(1) .ce-block__content > .ce-header,
.theme-layout-default .layout--content-28 .primary .ce-block:nth-child(2) .ce-block__content > .ce-header {
    margin-top: 0;
    margin-bottom: 50px;
    padding-top: 0;
    padding-bottom: 0;
}

.theme-layout-default .layout--content-28 .primary .ce-block:nth-child(1) .ce-block__content > .ce-header {
    margin-bottom: 10px;
}

/* Field */

.input-month-year-picker-wrap {
    position: relative;
    width: 196px;
    max-width: 196px;
}

.input-month-year-picker {
    background: url('../images/select-arrow.png') no-repeat 100% 50% #fff;
    cursor: pointer;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

/*...*/

.input-month-year-picker-wrap svg {
    cursor: pointer;
    color: hsl(0, 0%, 80%);
    fill: currentColor;
    stroke: currentColor;
    line-height: 1;
    stroke-width: 0;
    position: absolute;
    top: 11px;
    right: 40px;
    height: 17px;
    width: 17px;
}

.input-month-year-picker-wrap:hover svg {
    color: rgb(84, 84, 84);
}

/* Month Picker - Container */

.input-month-year-picker .anchor {
    display: inline;
    background: red;
    width: 1px;
    height: 2px;
}

.input-month-year-picker-tooltip-month.bottom {
    opacity: 1.0;
}

.input-month-year-picker-tooltip-month.bottom > .tooltip-arrow {
    display: none;
}

.input-month-year-picker-tooltip-month.bottom > div > .tooltip-arrow {
    border-bottom-color: #DFE6F4;
    margin-left: -106px;
}

.input-month-year-picker-tooltip-month.bottom .tooltip-inner {
    background: #F6F9FF;
    border: 1px solid #DFE6F4;
    color: #000;
    width: 250px;
    max-width: 250px;
    padding-bottom: 10px;
}

/* Month Picker - Year */

.input-month-year-picker-tooltip-month.bottom .row-year {
}

.input-month-year-picker-tooltip-month.bottom .year {
    background: rgba(51, 94, 234, 0.1);
    display: inline-block;
    margin: 10px 0 5px 0;
    padding: 8px 12px;
    border-radius: 4px;
}

.input-month-year-picker-tooltip-month.bottom .year-choose {
    margin-left: 5px;
}

.input-month-year-picker-tooltip-month.bottom .year-prev {
    margin: 20px 10px 10px 10px;
}

.input-month-year-picker-tooltip-month.bottom .year-next {
    margin: 20px 10px 10px 10px;
}

/* Month Picker - Month */

.input-month-year-picker-tooltip-month.bottom .month {
    padding: 5px 0;
    margin: 10px 8px;
    line-height: 1em;
    text-align: center;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.input-month-year-picker-tooltip-month.bottom .month.current {
    background: #1A91EB;
    color: #fff;
}

.input-month-year-picker-tooltip-month.bottom .month:not(.clickable) {
    color: #ccc;
}

/* Year Picker - Container */

.input-month-year-picker-tooltip-year.bottom {
    margin-left: 159px;
    margin-top: -51px;
}

.input-month-year-picker-tooltip-year.bottom .tooltip-arrow {
    border-bottom-color: #DFE6F4;
    display: none;
}

.input-month-year-picker-tooltip-year.bottom .tooltip-inner {
    background: #F6F9FF;
    border: 1px solid #DFE6F4;
    color: #000;
    height: 230px;
    overflow: auto;
    padding: 10px 0;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.input-month-year-picker-tooltip-year.bottom .tooltip-inner::-webkit-scrollbar {
    display: none;
}

.input-month-year-picker-tooltip-year.bottom .year {
    padding: 7px 15px;
    line-height: 1em;
    margin: 0px -1px;
}

.input-month-year-picker-tooltip-year.bottom .year.current {
    background: #1A91EB;
    color: #fff;
}

.actions { margin: 60px 0 100px 0; }
.panel-body > .actions:first-child { margin-top: 0; }
.actions button, .actions a.btn { margin-left: 0; margin-right: 10px; }
.actions button.pull-right, .actions a.btn.pull-right { margin-left: 15px; margin-right: 0; }
.actions button+button, .actions a.btn+a.btn { margin-left: 5px; }

@media screen and (max-width: 768px) {
    .actions { margin-bottom: 40px; }
    .actions button, .actions a.btn { display: block; margin-left: 0; margin-right: 0; margin-bottom: 30px; width: 100%; }
    .actions button+button, .actions a.btn+a.btn { margin-left: 0; }
}

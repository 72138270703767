.theme-layout-default .layout--post-1 {
    padding: 80px 0;
}

.theme-layout-default .layout--post-1 .primary .ce-block__content > * {
    text-align: left !important;
}

/* Date */

.theme-layout-default .layout--post-1 .primary .ce-block:nth-child(1) .ce-block__content > .ce-paragraph {
    font-family: "Poppins", arial, sans-serif;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 25px !important;
    margin: 0 0 30px 0;
    padding: 0;
}

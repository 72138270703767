/* Dropdown */

.dropdown .btn-xs { font-size: 11px; padding: 1px 6px 0 5px; font-weight: 500; }
.dropdown .btn-xs .caret { margin-left: 3px; }
.dropdown.disabled .caret { display: none; }
.dropdown > ul > li > a:hover { background: #F5F8FA none; }

/* Dropdown Menu*/

.dropdown-menu { font-size: 12px; left: 50%; right: auto; padding: 8px 0; text-align: center; transform: translate(-50%, 0); }
.dropdown-menu > li > a { padding: 8px 20px;}

/* Resets */

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,500,700,800&display=swap');

.container .layout .container {
    width: auto;
}

.theme-layout-default {
    font-family: "Open Sans", arial, sans-serif;
    font-weight: normal;
    overflow-x: hidden;
}

.theme-layout-default .layout {
}

.layout [contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

/* Headers */

.theme-layout-default h1.ce-header {
    color: #000;
    font-family: "Poppins", arial, sans-serif;
    font-size: 58px !important;
    font-weight: 600 !important;
    line-height: 70px !important;
    margin: 30px 0 30px 0;
    padding: 0;
}

.theme-layout-default h2.ce-header {
    color: #000;
    font-family: "Poppins", arial, sans-serif;
    font-size: 43px !important;
    font-weight: 500 !important;
    line-height: 52px !important;
    margin: 30px 0 30px 0;
    padding: 0;
}

.theme-layout-default h3.ce-header {
    color: #000;
    font-family: "Poppins", arial, sans-serif;
    font-size: 35px !important;
    font-weight: 500 !important;
    line-height: 41px !important;
    margin: 30px 0 30px 0;
    padding: 0;
}

.theme-layout-default h4.ce-header {
    color: #000;
    font-family: "Poppins", arial, sans-serif;
    font-size: 30px !important;
    font-weight: 500 !important;
    line-height: 36px !important;
    margin: 30px 0 30px 0;
    padding: 0;
}

.theme-layout-default h5.ce-header {
    color: #000;
    font-family: "Poppins", arial, sans-serif;
    font-size: 25px !important;
    font-weight: 500 !important;
    line-height: 30px !important;
    margin: 30px 0 30px 0;
    padding: 0;
}

.theme-layout-default h6.ce-header {
    color: #000;
    font-family: "Poppins", arial, sans-serif;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 25px !important;
    margin: 30px 0 30px 0;
    padding: 0;
}

/* Files */

.theme-layout-default .files-tool,
.theme-layout-default .cdx-block.files-tool {
    margin: 30px 0 20px 0 !important;
    padding: 0 !important;
}

/* Images */

.theme-layout-default .binary-images-with-upload .image-container,
.theme-layout-default .binary-images-with-upload .image,
.theme-layout-default .binary-images-with-upload .image img {
    -moz-border-radius: 15px !important;;
    -webkit-border-radius: 15px !important;;
    border-radius: 15px !important;
}

/* Video Embeds */

.theme-layout-default .embed-tool iframe, div.input-inline-editor.flushed-exact .ce-embed iframe {
    -moz-border-radius: 15px !important;;
    -webkit-border-radius: 15px !important;;
    border-radius: 15px !important;
}

/* Containers */

@media screen and (max-width: 992px) {
    .theme-layout-default .row > div:not(:last-child),
    .theme-layout-default .row:not(:last-child) > div {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 768px) {
    .theme-layout-default .row > div:not(:last-child),
    .theme-layout-default .row:not(:last-child) > div {
        margin-bottom: 40px;
    }
}

.theme-layout-default .layout {
    background: #fff;
    position: relative;
}

.theme-layout-default .layout:nth-child(even) {
    background: #fafafa;
}

.theme-layout-default .layout:not(:last-child) {
    /*border-bottom: 1px solid #e8e8e8;*/
}

.theme-layout-default .layout.has-background {
    background-repeat: no-repeat;
    background-size: cover;
}

.theme-layout-default .layout.has-background .background-overlay {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.theme-layout-default .layout > .layout-inner-wrap > .container {
    position: relative;
}

.theme-layout-default .layout.editing:hover > .layout-inner-wrap > .container {
    background: #fafafa;
    -moz-box-shadow: 0 0 0 1px #e8e8e8 inset;
    -webkit-box-shadow: 0 0 0 1px #e8e8e8 inset;
    box-shadow: 0 0 0 1px #e8e8e8 inset;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.theme-layout-default .layout.has-background.editing:not(.invert-no):hover > .layout-inner-wrap > .container {
    background: rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 0 1px #666 inset;
    -webkit-box-shadow: 0 0 0 1px #666 inset;
    box-shadow: 0 0 0 1px #666 inset;
}

/* Vertical spacing - vsm */

.theme-layout-default .layout .vsm .ce-block:not(:first-child) .cdx-block,
.theme-layout-default .layout .vsm .ce-block:not(:first-child) .ce-header,
.theme-layout-default .layout .vsm .ce-block:not(:first-child) .ce-paragraph,
.theme-layout-default .layout .vsm .ce-block:not(:first-child) .cdx-list,
.theme-layout-default .layout .vsm .ce-block:not(:first-child) .form-group-binary-images-with-upload {
    margin-top: 0.5em !important;
}

.theme-layout-default .layout .vsm .ce-block:not(:first-child) .buttons {
    margin-top: 10px !important;
}

.theme-layout-default .layout .vsm .ce-block:not(:last-child) .cdx-block,
.theme-layout-default .layout .vsm .ce-block:not(:last-child) .ce-header,
.theme-layout-default .layout .vsm .ce-block:not(:last-child) .ce-paragraph,
.theme-layout-default .layout .vsm .ce-block:not(:last-child) .cdx-list,
.theme-layout-default .layout .vsm .ce-block:not(:last-child) .form-group-binary-images-with-upload,
.theme-layout-default .layout .vsm .ce-block:not(:last-child) .buttons {
    margin-bottom: 0.5em !important;
}

.theme-layout-default .layout .vsm .ce-block:not(:last-child) .buttons {
    margin-bottom: 10px !important;
}

.theme-layout-default .layout .vsm .cdx-list {
    margin-top: 0.5em !important;
}

/* Management Controls */

.theme-layout-default .layout .layout-management-controls {
    position: absolute;
    top: 0;
    left: 0;
}

.theme-layout-default .layout .layout-management-controls > span {
    background: #ccc;
    color: #000;
    display: block;
    float: left;
    opacity: 0.2;
    font-size: 10px;
    line-height: 1em;
    text-transform: uppercase;
    margin-right: 1px;
    -moz-border-radius: 0 0 4px 0;
    -webkit-border-radius: 0 0 4px 0;
    border-radius: 0 0 4px 0;
}

.theme-layout-default .layout.has-background .layout-management-controls > span {
    background: #000;
    color: #fff;
}

.theme-layout-default .layout:hover .layout-management-controls > span {
    opacity: 0.5;
}

.theme-layout-default .layout:hover .layout-management-controls > span:hover {
    background: #1A91EB;
    color: #fff;
    opacity: 1.0;
}

.theme-layout-default .layout .layout-management-controls > span > span {
    display: block;
    float: left;
    padding: 8px 12px;
}

.theme-layout-default .layout .layout-management-controls > span > span:not(:nth-child(1)) {
    border-left: 1px solid #333;
    padding-left: 10px;
}

.theme-layout-default .layout .layout-management-controls > span > span:not(:nth-child(1)):hover {
    background: #3071a9;
}

.theme-layout-default .layout .layout-management-controls > span > span > .fa {
    font-size: 9px;
}

.theme-layout-default .layout .layout-management-controls > span.change-background .dropzone {
    width: 0;
    height: 0;
}

/* Block Delete */

.theme-layout-default .layout .block > .block-delete {
    background: #ccc;
    border: 0;
    color: #000;
    font-size: 10px;
    opacity: 0.4;
    padding: 5px 10px;
    margin: 0;
    position: absolute;
    top: -24px;
    right: 25px;
    overflow: visible;
    text-align: center;
    text-transform: uppercase;
    visibility: hidden;
    z-index: 50;
    -moz-border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.theme-layout-default .layout.has-background .block > .block-delete {
    background: #000;
    color: #fff;
    opacity: 0.4;
}

.theme-layout-default .layout .blocks:not(.sorting) > .block:hover > .block-delete {
    visibility: visible;
}

.theme-layout-default .layout .blocks > .block > .block-delete:hover {
    background: #c00;
    color: #fff;
    opacity: 1.0;
}

/* Common to elements */

.theme-layout-default .layout > .layout-inner-wrap > *:first-child {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.theme-layout-default .layout > .layout-inner-wrap > *:last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.theme-layout-default .layout.has-background:not(.invert-no) .ce-block .ce-block__content > * {
    color: #fff;
}

/* Editor */

.theme-layout-default .layout .form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact:not(.read-only):before {
    border: 1px dashed transparent;
}

.theme-layout-default .layout:hover .form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact:not(.read-only):before {
    background: #fff;
    border: 1px dashed #ccc;
}

.theme-layout-default .layout.has-background.editing:not(.invert-no) .form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact:not(.read-only):before {
    border: 1px dashed transparent;
}

.theme-layout-default .layout.has-background.editing:not(.invert-no):hover .form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact:not(.read-only):before {
    background: #000;
    border: 1px dashed #666;
}

.theme-layout-default .layout div.input-inline-editor.flushed-exact {
    padding: 0;
    margin-bottom: -5px;
}

.theme-layout-default .layout div.input-inline-editor.flushed-exact.read-only .codex-editor {
    margin-top: 0;
    margin-bottom: 0;
}

.theme-layout-default .layout div.input-inline-editor.flushed-exact .ce-block:last-child,
.theme-layout-default .layout div.input-inline-editor.flushed-exact .ce-block:last-child > .ce-block__content > *,
.theme-layout-default .layout div.input-inline-editor.flushed-exact .ce-block:last-child > *:last-child {
    margin-bottom: 0 !important;
}

.theme-layout-default .form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor .ce-toolbar__content .ce-toolbar__plus {
    background: #1A91EB;
    color: #fff;
    width: 28px;
    height: 28px;
}

.theme-layout-default .form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor .ce-toolbar__actions .ce-toolbar__actions-buttons {
    background: transparent;
    /*background: #1A91EB;*/
    /*color: #fff;*/
}

.theme-layout-default .form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor .ce-toolbar__actions .ce-toolbar__actions-buttons .ce-toolbar__settings-btn {
    background: #1A91EB;
    color: #fff;
    width: 28px;
    height: 28px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.theme-layout-default div.input-inline-editor.flushed-exact .cdx-block {
    /*padding: 0.7em 0;*/
    padding: 0;
}

.theme-layout-default .ce-header {
    margin: 1em 0;
    padding: 0;
}

.theme-layout-default .layout:hover .form-group-input-inline-editor div.input-inline-editor:not(.read-only) .spacer {
    border-color: #ccc;
}

/* Buttons */

.theme-layout-default .layout .buttons {
    margin: 40px 0 20px 0;
}

/* Button */

.theme-layout-default .layout .btn {
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
}

.theme-layout-default .layout .btn input[type="text"] {
    background: transparent;
    border: none;
    text-align: center;
    width: calc(100% - 80px);
}

/* Button - Normal */

.theme-layout-default .layout .btn {
    font-size: 16px;
    font-weight: 500;
    padding: 15px 35px;
}

/* Button - Extra Small */

.theme-layout-default .layout .btn-xs {
    font-size: 10px;
    font-weight: 500;
    padding: 5px 15px;
}

/* Button - Small */

.theme-layout-default .layout .btn-sm {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
}

/* Button - Medium */

.theme-layout-default .layout .btn-md {
    font-size: 16px;
    font-weight: 500;
    padding: 15px 30px;
}

/* Button - Large */

.theme-layout-default .layout .btn-lg {
    font-size: 20px;
    font-weight: 500;
    padding: 18px 40px;
}

/* Button - Extra Large */

.theme-layout-default .layout .btn-xl {
    font-size: 25px;
    font-weight: 500;
    padding: 20px 40px;
}


/* Button - Default */

.theme-layout-default .layout .btn-default,
.theme-layout-default .layout .btn-default.active,
.theme-layout-default .layout .btn-default.disabled,
.theme-layout-default .layout .btn-default[disabled],
.theme-layout-default .layout .btn-default[disabled]:focus,
.theme-layout-default .layout .btn-default[disabled]:active,
.theme-layout-default .layout .btn-default[disabled]:hover {
    background-color: rgba(51, 94, 234, 0.1);
    border-color: rgba(51, 94, 234, 0.1);
    color: #1A91EB;
}

.theme-layout-default .layout .btn-default:hover,
.theme-layout-default .layout .btn-default:focus,
.theme-layout-default .layout .btn-default.active:hover,
.theme-layout-default .layout .btn-default:active:hover,
.theme-layout-default .layout .btn-default:active:focus {
    background-color: rgba(51, 94, 234, 0.2);
    border-color: rgba(51, 94, 234, 0.2);
    color: #1A91EB;
}

/* Button - Primary */

.theme-layout-default .layout .btn-primary,
.theme-layout-default .layout .btn-primary.active,
.theme-layout-default .layout .btn-primary.disabled,
.theme-layout-default .layout .btn-primary[disabled],
.theme-layout-default .layout .btn-primary[disabled]:focus,
.theme-layout-default .layout .btn-primary[disabled]:active,
.theme-layout-default .layout .btn-primary[disabled]:hover {
    background-color: #1A91EB;
    border-color: #1A91EB;
    color: #fff;
}

.theme-layout-default .layout .btn-primary:hover,
.theme-layout-default .layout .btn-primary:focus,
.theme-layout-default .layout .btn-primary.active:hover,
.theme-layout-default .layout .btn-primary:active:hover,
.theme-layout-default .layout .btn-primary:active:focus {
    background-color: #3071A9;
    border-color: #3071A9;
    color: #fff;
}

/* Button - Link */

.theme-layout-default .layout .btn-link,
.theme-layout-default .layout .btn-link.active,
.theme-layout-default .layout .btn-link.disabled,
.theme-layout-default .layout .btn-link[disabled],
.theme-layout-default .layout .btn-link[disabled]:focus,
.theme-layout-default .layout .btn-link[disabled]:active,
.theme-layout-default .layout .btn-link[disabled]:hover {
    background-color: transparent;
    border-color: transparent;
    color: #1A91EB;
}

.theme-layout-default .layout .btn-link:hover,
.theme-layout-default .layout .btn-link:focus,
.theme-layout-default .layout .btn-link.active:hover,
.theme-layout-default .layout .btn-link:active:hover,
.theme-layout-default .layout .btn-link:active:focus {
    background-color: transparent;
    border-color: transparent;
    color: #3071A9;
    text-decoration: none;
}

.theme-layout-default .layout .list.blocks.grid {
    margin-bottom: 0 !important;
}

.theme-layout-default .layout .container > .row:last-child .list.blocks.grid {
    margin-bottom: -30px !important;
}

.theme-layout-default .layout .buttons .list.blocks.horizontal .block:nth-child(even) {
    /*background: #fafafa;*/
}

.theme-layout-default .layout .buttons .list.blocks.horizontal .block:nth-child(odd) {
    /*background: #e8e8e8;*/
}

.theme-layout-default .layout .buttons .list.blocks.horizontal .block.block-btn-link {
    padding-left: 10px;
    padding-right: 10px;
}

.theme-layout-default .layout.editing .buttons .list.blocks.horizontal .block.block-btn-link:hover:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px dashed #ccc;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
}

.theme-layout-default .layout .buttons .list.blocks.horizontal .block.block-btn-link .btn {
    padding-left: 0;
    padding-right: 0;
}

.theme-layout-default .layout.editing .buttons .block.block-btn-link:hover .delete {
    right: -10px;
}
.panel-table { background: #fefefe; -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, .1); -moz-box-shadow: 0 0 1px rgba(0, 0, 0, .1); box-shadow: 0 0 1px rgba(0, 0, 0, .1); }
.panel-table { background: #fefefe; border: 1px solid #E6EAEE; margin-bottom: 20px; }
.panel-table { overflow: visible; }
.panel-table td > .form-group { margin-bottom: 0; }

.table { margin-bottom: 0; }
.table > thead > tr > th { background: #fff; border-bottom: 1px solid #DFE6F4; color: #56618F; font-size: 12px; font-weight: 500; padding-top: 15px; padding-bottom: 15px; text-transform: uppercase; vertical-align: middle; white-space: nowrap; }
.table > tfoot > tr > td { padding-top: 13px; padding-bottom: 13px; vertical-align: middle; white-space: nowrap; }
.table > thead > tr > th:first-child, .table > tfoot > tr > td:first-child { padding-left: 20px; -moz-border-radius-topleft: 4px; -webkit-border-top-left-radius: 4px; border-top-left-radius: 4px; }
.table tr:first-child > th:last-child, .table tr:first-child > td:last-child { -moz-border-radius-topright: 4px; -webkit-border-top-right-radius: 4px; border-top-right-radius: 4px; }
.table > thead > tr > th:last-child, .table > tfoot > tr > td:last-child { padding-right: 20px; }
.table > tbody > tr > td { padding-top: 13px; padding-bottom: 13px; vertical-align: middle; }
.table > tbody > tr > td:first-child { padding-left: 20px; }
.table > tbody > tr > td:last-child { padding-right: 20px; }
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td { border-top: 1px solid #f5f8fa; }
.table > thead > tr > th .fa { font-size: 9px; margin-left: 8px; opacity: 0.5; }
.table > thead > tr > th .fa.active { opacity: 1.0; }
.table > thead > tr > th .fa.fa-chevron-down { position: relative; top: -1px; }
.table tbody tr:first-child > td { border-top: 0; }
.table > tbody > tr:hover > td { background: #FAFBFC; }
.table button.delete { background: 0 0; border: 0; color: #000; cursor: pointer; font-size: 21px; font-weight: 700; line-height: 1; padding: 0; text-shadow: 0 1px 0 #fff; opacity: .2; filter: alpha(opacity=20); -webkit-appearance:none; }
.table strong { font-weight: 600; }
.table td.text-right > .btn { margin-left: 5px; }
.table td.text-right > div:not(.btn-group) > .btn { margin-left: 5px; }
.table td.text-right .fa { margin-left: 20px; }
.table td.icon-only { line-height: 0px; }
.table th.value { background: #fff; padding-left: 20px; font-weight: normal; }
.table tr > th.col-icon { width: 15px; }
.table tr > td.col-icon { vertical-align: top; padding-top: 16px; }
.table tr.nested-1 > td { background: #F5F8FA; }
.table tr.nested-1 > td .indented { padding-left: 20px; }
.table tr > td.nested-1 { background: #F5F8FA; }
.table tr > td.nested-1 .indented { padding-left: 20px; }
.table th.col-nowrap, .table td.col-nowrap { padding-right: 40px; width: 1px; white-space: nowrap; }
.table th.col-tight-fit, .table td.col-tight-fit { width: 1px; }
.table th.col-status { width: 150px; }
.table th.col-chevron { width: 30px; }
.table th.col-date { width: 130px; }
.table .blank-message { padding: 50px 0; }
.table .blank-message + .blank-action { margin-top: -30px; margin-bottom: 50px; }
.table strong { font-weight: 500; }

.table-responsive { overflow: auto; border-color: #DFE6F4; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; }
.panel .table-responsive { overflow: unset; }
.panel .table-responsive.overflow-visible { overflow: visible; }

@media screen and (max-width: 1200px) {
    .table-responsive>.table>thead>tr>th, .table-responsive>.table>tbody>tr>th, .table-responsive>.table>tfoot>tr>th, .table-responsive>.table>thead>tr>td, .table-responsive>.table>tbody>tr>td, .table-responsive>.table>tfoot>tr>td { padding-right: 30px; }
    .table-responsive>.table>thead>tr>th:last-child, .table-responsive>.table>tbody>tr>th:last-child, .table-responsive>.table>tfoot>tr>th:last-child, .table-responsive>.table>thead>tr>td:last-child, .table-responsive>.table>tbody>tr>td:last-child, .table-responsive>.table>tfoot>tr>td:last-child { padding-right: 20px; }
}

@media screen and (max-width: 768px) {
    .table-responsive>.table>thead>tr>th, .table-responsive>.table>tbody>tr>th, .table-responsive>.table>tfoot>tr>th, .table-responsive>.table>thead>tr>td, .table-responsive>.table>tbody>tr>td, .table-responsive>.table>tfoot>tr>td { padding-right: 50px; }
    .table-responsive>.table>thead>tr>th:last-child, .table-responsive>.table>tbody>tr>th:last-child, .table-responsive>.table>tfoot>tr>th:last-child, .table-responsive>.table>thead>tr>td:last-child, .table-responsive>.table>tbody>tr>td:last-child, .table-responsive>.table>tfoot>tr>td:last-child { padding-right: 20px; }
}

@media screen and (max-width: 767px) {
    .table-responsive { overflow: auto; }
    .panel .table-responsive { overflow: unset; }
}

@media (min-width: 1200px) {
    .table-responsive { overflow: visible; }
}

.theme-layout-default .layout--content-12 > div {
    padding: 80px 0;
}

/* Header */

.theme-layout-default .layout--content-12 .primary .ce-block .ce-block__content > .ce-header {
    margin-bottom: 10px;
    padding-bottom: 0;
}

/* Description */

.theme-layout-default .layout--content-12 .primary .ce-block .ce-block__content > .ce-paragraph {
    color: #919dab;
    font-family: "Poppins", arial, sans-serif;
    font-size: 18px;
    line-height: 28px;
}

/* Thumbnail */

.theme-layout-default .layout--content-12 .side .ce-block:first-child .binary-images-with-upload .image {
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
}
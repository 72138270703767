.theme-layout-default .layout--footer-2 > div {
    padding: 80px 0;
}

.theme-layout-default .layout--footer-2.has-background .row-footer {
    border-top: 2px solid rgba(255, 255, 255, 0.3);
}

.theme-layout-default .layout--footer-2 .primary .ce-block__content > *,
.theme-layout-default .layout--footer-2 .feature1 .ce-block__content > *,
.theme-layout-default .layout--footer-2 .feature2 .ce-block__content > * {
    text-align: left !important;
}

.theme-layout-default .layout--footer-2 .feature3 .ce-block__content > * {
    text-align: right !important;
}

/* Header */

.theme-layout-default .layout--footer-2 .ce-block .ce-block__content > .ce-header {
    font-family: "Poppins", arial, sans-serif;
}

/* Description */

.theme-layout-default .layout--footer-2 .ce-block .ce-block__content > .ce-paragraph {
    color: #919dab;
    font-family: "Poppins", arial, sans-serif;
    font-size: 13px;
    line-height: 20px;
}

/* Buttons */

.theme-layout-default .layout--footer-2 .ce-block:first-child:last-child .buttons {
    margin-top: 0;
    margin-bottom: 30px;
}

.theme-layout-default .layout--footer-2 .ce-block:last-child .buttons {
    margin-top: 20px;
    margin-bottom: 0;
}

.theme-layout-default .layout--footer-2 .buttons .list.blocks.horizontal .block.block-btn-link {
    margin-bottom: 0;
}

.theme-layout-default .layout--footer-2 .buttons .btn-link,
.theme-layout-default .layout--footer-2 .buttons .btn-link.active,
.theme-layout-default .layout--footer-2 .buttons .btn-link.disabled,
.theme-layout-default .layout--footer-2 .buttons .btn-link[disabled],
.theme-layout-default .layout--footer-2 .buttons .btn-link[disabled]:focus,
.theme-layout-default .layout--footer-2 .buttons .btn-link[disabled]:active,
.theme-layout-default .layout--footer-2 .buttons .btn-link[disabled]:hover {
    color: #919dab;
}

.theme-layout-default .layout--footer-2 .buttons .btn-link:hover,
.theme-layout-default .layout--footer-2 .buttons .btn-link:focus,
.theme-layout-default .layout--footer-2 .buttons .btn-link.active:hover,
.theme-layout-default .layout--footer-2 .buttons .btn-link:active:hover,
.theme-layout-default .layout--footer-2 .buttons .btn-link:active:focus {
    color: #525d6b;
}
/* Native */

select, select.form-control, div.input-rich-text .input-rich-text-toolbar select { background: url('../images/select-arrow.png') no-repeat 100% 50% #fff; border: 1px solid #E6EAEE; color: #1A91EB; line-height: 34px; padding: 0 42px 0 10px; -moz-appearance: none; -webkit-appearance: none; appearance: none; -moz-border-radius: 3px; -webkit-border-radius: 3px; border-radius: 4px; -moz-appearance: none; text-indent: 0.01px; text-overflow: ''; }
select.blank, select.form-control.blank { color: #999; }
select.full { width: 100%; }
select:focus { outline: 0; border: 1px solid #1A91EB; }
select:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }

/* Enhanced */

.input-select-enhanced-container > .input-select-enhanced { font-family: arial, helvetica, sans-serif; font-size: 14px; }
.input-select-enhanced-container > .input-select-enhanced > div { background-color: #fff; border-color: #E6EAEE; }
.input-select-enhanced-container > .input-select-enhanced > div > .__value-container .__input { color: #1A91EB; }
.input-select-enhanced-container > .input-select-enhanced.--is-disabled > div > .__value-container > .__single-value { color: #999; }
.input-select-enhanced-container > .input-select-enhanced.--is-disabled > div { background-color: #f6f6f6; }
.input-select-enhanced-container > .input-select-enhanced > .__control { min-height: 40px; -moz-box-shadow: 0 0; -webkit-box-shadow: 0 0; box-shadow: 0 0; }
.has-warning .input-select-enhanced-container > .input-select-enhanced > div { border-color: #1A91EB; }
.has-error .input-select-enhanced-container > .input-select-enhanced > div { border-color: #a94442; }
.input-select-enhanced-container > .input-select-enhanced > .__control-is-disabled { background: #f6f6f6; }
.input-select-enhanced-container > .input-select-enhanced > .__control-is-focused { -webkit-box-shadow: 0 0 8px rgba(80, 193, 233, 0.6); -moz-box-shadow: 0 0 8px rgba(80, 193, 233, 0.6); box-shadow: 0 0 8px rgba(80, 193, 233, 0.6); }
.input-select-enhanced-container > .input-select-enhanced > div > .__value-container { padding: 2px 6px; }
.input-select-enhanced-container > .input-select-enhanced > div > .__value-container > .__single-value { color: #1A91EB; }
.required .input-select-enhanced-container > .input-select-enhanced > div > .__value-container > .__single-value { color: #1A91EB; }
.input-select-enhanced-container > .input-select-enhanced > .__control-isFocused > .__value-container > div { color: #ccc; /*opacity: 0;*/ }
.input-select-enhanced-container > .input-select-enhanced .__clear-indicator { padding: 6px 8px; }
.input-select-enhanced-container > .input-select-enhanced .__clear-indicator svg { height: 17px; width: 17px; }
.input-select-enhanced-container > .input-select-enhanced .__indicator-separator { display: none; }
.input-select-enhanced-container > .input-select-enhanced .__dropdown-indicator { background: url('../images/select-arrow.png') no-repeat 100% 50% transparent; padding: 15px 16px; width: 30px; -moz-border-radius: 0 4px 4px 0; --border-radius: 0 4px 4px 0; border-radius: 0 4px 4px 0; }
.input-select-enhanced-container > .input-select-enhanced .__dropdown-indicator svg { display: none; }
.input-select-enhanced-container > .input-select-enhanced .__menu { background: transparent; border: 0; margin-top: -3px; padding-bottom: 30px; z-index: 52; -moz-border-radius: 0 0 4px 4px; -webkit-border-radius: 0 0 4px 4px; border-radius: 0 0 4px 4px; -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; }
.has-warning .input-select-enhanced-container > .input-select-enhanced .__menu { }
.input-select-enhanced-container > .input-select-enhanced .__menu-list { background: #fff; border: 1px solid #E6EAEE; padding: 0; -moz-border-radius: 0 0 4px 4px; -webkit-border-radius: 0 0 4px 4px; border-radius: 0 0 4px 4px; -webkit-box-shadow: 0 5px 20px #f0f0f0; -moz-box-shadow: 0 5px 20px #f0f0f0; box-shadow: 0 5px 20px #f0f0f0; }
.has-warning .input-select-enhanced-container > .input-select-enhanced .__menu-list { border-color: #1A91EB; }
.has-error .input-select-enhanced-container > .input-select-enhanced .__menu-list { border-color: #a94442; }
.input-select-enhanced-container > .input-select-enhanced .__menu .__option { cursor: pointer; padding-top: 7px; padding-bottom: 7px; }
.input-select-enhanced-container > .input-select-enhanced .__menu .__option:not(:last-child) { border-bottom: 1px solid #f0f0f0; }
.input-select-enhanced-container > .input-select-enhanced .__menu .__option--is-focused { background: #F5F8FA; }
.input-select-enhanced-container > .input-select-enhanced .__menu .__option--is-selected { background: #1A91EB; }
.input-select-enhanced-container .__menu-notice { text-align: left; visibility: hidden; }

.form-group-lg > .input-select-enhanced-container { min-width: 280px; }
.input-select-enhanced-container > .input-select-enhanced.menu-lg > .__menu { min-width: 280px; }

.tertiary-nav {}
.tertiary-nav .tabbed-pills-container > .nav { background: transparent;font-family: "Effra Regular", Helvetica, Arial, sans-serif;font-size: 11px; font-weight: 400; margin-top: -10px; margin-bottom: 40px; opacity: 1.0; letter-spacing: 1px; text-transform: uppercase; -moz-border-radius: 0; -webkit-border-radius: 0; border-radius: 0; }
.tertiary-nav .tabbed-pills-container > .nav:not(.nav-justified) { border-bottom: 1px solid #E6EAEE; }
.tertiary-nav .tabbed-pills-container > .nav > li { margin: 0; }
.tertiary-nav .tabbed-pills-container > .nav > li a { border-bottom: 2px solid transparent; -moz-border-radius: 0; -webkit-border-radius: 0; border-radius: 0; font-weight: 300; }
.tertiary-nav .tabbed-pills-container > .nav:not(.nav-justified) > li a { margin-right: 30px; padding-left: 0; padding-right: 0; padding-bottom: 18px; }
.tertiary-nav .tabbed-pills-container > .nav > li.active a { background: transparent; border-bottom-color: #1a91eb; color: #1a91eb; }
.tertiary-nav .tabbed-pills-container > .nav > li:not(.active) > a { color: #7F8FA4; }
.tertiary-nav .tabbed-pills-container > .nav > li:not(.active) > a:hover { background: transparent; color: #2F3C4E; }
.tertiary-nav .tabbed-pills-container > .nav > li.disabled > a { color: #AAB0B8; opacity: 0.3; }

@media (max-width: 1199px) {
    .tertiary-nav .tabbed-pills-container > .nav:not(.nav-justified) { border-bottom: 0; }
    .tertiary-nav .tabbed-pills-container > .nav:not(.nav-justified).expanded { margin-bottom: 20px; }
    .tertiary-nav .tabbed-pills-container > .nav > li { display: block; margin-left: 0; width: 100%; }
    .tertiary-nav .tabbed-pills-container > .nav > li > a { margin-bottom: 0; text-align: left; }
    .tertiary-nav .tabbed-pills-container > .nav:not(.nav-justified) > li a { margin-right: 0; }
    .tertiary-nav .tabbed-pills-container > .nav > li.active > a:after { content: "\f078"; font-family: "FontAwesome"; position: absolute; right: 10px; }
    .tertiary-nav .tabbed-pills-container > .nav.expanded > li.active { margin-bottom: 20px; }
    .tertiary-nav .tabbed-pills-container > .nav.expanded > li.active > a:after { content: "\f077"; }
    .tertiary-nav .tabbed-pills-container > .nav:not(.expanded) > li:not(.active) { display: none; }
}

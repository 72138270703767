.pagination-container { text-align: center; }
.pagination-container.left { text-align: left; }
.pagination-container.center { text-align: center; }
.pagination-container.right { text-align: right; }

.pagination > li > a, .pagination > li.disabled > a { border-color: #DFE6F4; }
.pagination li a:hover, .pagination li.active a:hover { background-color: #ccd1d9; color: #fff; }
.pagination li.active a, .pagination li.active a:hover, .pagination li.active a:focus, .pagination li.active a:active { background: #1A91EB; border-color: #ddd; }

.pagination-more { cursor: pointer; display: block; margin: 20px 0 0 0; }

.modal.full { z-index: 1039; }
.modal.full .modal-body { max-height: 100%; }
.modal.full .modal-dialog { width: 100%; height: 100%; margin: 0; padding: 0; }
.modal.full .modal-content { background: #FAFBFC; height: auto; min-height: 100%; border-radius: 0; }
.modal.full .modal-header { background: transparent; border: 0; margin: 0 0 0 0; padding: 10px 30px; text-align: left; }
.modal.full .modal-header h1 { padding-top: 0; }
.modal.full .modal-header button.close { background: #aaa; border-radius:5px; font-size: 30px; line-height: 1em; position: absolute; right: 10px; width: 30px; height: 30px; z-index: 100; }
.modal.full .modal-body { background: transparent; border: 0; }
.modal.full .modal-footer { background: transparent; border: 0; }
/*.modal.full h1 { margin-bottom: 40px; }*/
.modal.full h1, .modal.full .h1 { text-align: left; }
.modal.full h1.text-center, .modal.full .h1.text-center { text-align: center; }
.modal.full.centered-title .modal-header h1, .modal.full.centered-title .modal-header .h1 { text-align: center; margin-left: 40px; margin-right: 40px; }
.modal.full .lead { margin-top: -40px; margin-bottom: 2em; text-align: left; }
.modal.full .lead.text-center { text-align: center; }

@media screen and (max-width: 768px) {
    .modal.full .modal-header { padding-left: 0; padding-right: 0; }
    .modal.full .modal-body { padding-left: 0; padding-right: 0; }
    .modal.full .modal-body .dialog-panel { background: transparent; border: 0; -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; }
    .modal.full .modal-body .dialog-panel .panel-body { padding: 0; }
    .modal.full .modal-body .dialog-panel .panel-body .dialog-panel-body { padding-left: 0; padding-right: 0; }
}

@media screen and (max-width: 1200px) {
    .modal.full .modal-header > .container { padding-left: 30px; padding-right: 30px; width: 100%; }
    .modal.full .modal-header > .container > .row > div { margin-left: 0; width: 100%; }
    .modal.full .modal-header > .container h1 { margin-top: 17px; font-size: 18px; }
    .modal.full .modal-body > .container { padding-left: 30px; padding-right: 30px; width: 100%; }
    .modal.full .modal-body > .container > .row > div { margin-left: 0; width: 100%; }
}

@media screen and (min-width: 1200px) {
    .modal.full .modal-header > .container h1 { margin-top: 100px; }
}

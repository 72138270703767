.binary-files-with-upload { overflow: hidden; position: relative; }

.binary-files-with-upload .image-container { margin: 6px 10px 10px 0; /*overflow: hidden;*/ position: relative; }

.binary-files-with-upload .file { height: auto; min-height: 48px; padding-left: 50px; margin-top: 5px; margin-bottom: 10px; position: relative; text-align: left; }
.binary-files-with-upload .file:before { color: #e8e8e8; content: "\f016"; font-family: FontAwesome; font-size: 34px; position: absolute; left: 5px; top: 0; }
.binary-files-with-upload .file.archive:before { content: "\f1c6"; }
.binary-files-with-upload .file.audio:before { content: "\f1c7"; }
.binary-files-with-upload .file.code:before { content: "\f1c9"; }
.binary-files-with-upload .file.image:before { content: "\f1c5"; }
.binary-files-with-upload .file.pdf:before { content: "\f1c1"; }
.binary-files-with-upload .file.text:before { content: "\f0f6"; }
.binary-files-with-upload .file.video:before { content: "\f1c8"; }
.binary-files-with-upload .file .name { display: block; padding-top: 5px; }
.binary-files-with-upload .file .size { color: #ccc; display: block; margin-top: -2px; }
.binary-files-with-upload .file .size span { margin-left: 10px; }
.binary-files-with-upload .file-input-mask { background: #fafafa; display: inline-block; position: relative; overflow: hidden; text-align: center; height: 100%; width: 100%; }
.binary-files-with-upload .file-input-mask p { line-height: 134px; }

.binary-files-with-upload .add { cursor: pointer; position: absolute !important; top: 0; left: 0; width: 100%; height: 100%; }
.binary-files-with-upload .remove { color: #e8e8e8; cursor: pointer; display: inline; margin-left: 8px; }
.binary-files-with-upload .remove:hover { color: #ccc; }
.binary-files-with-upload .status { color: #e8e8e8; display: inline; margin-left: 8px; margin-bottom: 10px; }

.theme-layout-default .layout--showcase-9 {
    padding: 80px 0;
}

.theme-layout-default .layout--showcase-9 .primary .ce-block__content > * {
    text-align: center !important;
}

/* Header */

.theme-layout-default .layout--showcase-9 .primary .ce-block:nth-child(1) .ce-block__content > .ce-header,
.theme-layout-default .layout--showcase-9 .primary .ce-block:nth-child(2) .ce-block__content > .ce-header {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.theme-layout-default .layout--showcase-9 .primary .ce-block:nth-child(2) .ce-block__content > .ce-header {
    margin-bottom: 50px;
}

/* Description */

.theme-layout-default .layout--showcase-9 .primary .ce-block:nth-child(1) .ce-block__content > .ce-paragraph,
.theme-layout-default .layout--showcase-9 .primary .ce-block:nth-child(2) .ce-block__content > .ce-paragraph {
    color: #919dab;
    font-family: "Poppins", arial, sans-serif;
    font-size: 18px;
    line-height: 28px;
    margin: 30px 50px 50px;
}

.theme-layout-default .layout--showcase-9 .primary .ce-block:nth-child(1) .ce-block__content > .ce-paragraph {
    margin-top: 0;
    margin-bottom: 10px;
}

/* Features */

.theme-layout-default .layout--showcase-9 .form-group-feature {
    background: #fafafa !important;
    text-align: left;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
}

.theme-layout-default .layout--showcase-9:nth-child(even) .form-group-feature {
    background: #fff !important;
}

.theme-layout-default .layout--showcase-9.has-background .form-group-feature {
    background: #fff;
}

.theme-layout-default .layout--showcase-9.has-background.editing:not(.invert-no):hover .form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact:not(.read-only):before {
    background: transparent;
}

.theme-layout-default .layout--showcase-9 .feature .ce-block .ce-block__content > * {
    color: #555 !important;
}

.theme-layout-default .layout--showcase-9 .feature .ce-block:last-child .ce-block__content {
    padding-bottom: 30px !important;
}

.theme-layout-default .layout--showcase-9 .feature .ce-block:nth-last-child(2) {
    padding-bottom: 80px;
}

.theme-layout-default .layout--showcase-9 .feature .ce-block:last-child {
    position: absolute;
    bottom: 0;
}

/* Features - Image */

.theme-layout-default .layout--showcase-9 .feature .ce-block:nth-child(1) .ce-block__content {
    margin-top: -1px;
}

.theme-layout-default .layout--showcase-9 .feature .binary-images-with-upload .image-container,
.theme-layout-default .layout--showcase-9 .feature .binary-images-with-upload .add {
    height: 270px;
}

.theme-layout-default .layout--showcase-9 .feature .ce-images .binary-images-with-upload {
    min-height: 275px;
}

.theme-layout-default .layout--showcase-9 .feature .binary-images-with-upload .image-container,
.theme-layout-default .layout--showcase-9 .feature .binary-images-with-upload .image-container img,
.theme-layout-default .layout--showcase-9 .feature .binary-images-with-upload .add {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.theme-layout-default .layout--showcase-9 .feature .binary-images-with-upload .image-container img {
    object-fit: cover;
}

/* Features - Header */

.theme-layout-default .layout--showcase-9 .feature .ce-block:nth-child(2) .ce-block__content > .ce-header,
.theme-layout-default .layout--showcase-9 .feature .ce-block:nth-child(2) .ce-block__content > .ce-paragraph {
    font-family: "Poppins", arial, sans-serif;
    margin-top: 10px !important;
}

.theme-layout-default .layout--showcase-9 .feature .ce-block:nth-child(1) .ce-block__content > .ce-header,
.theme-layout-default .layout--showcase-9 .feature .ce-block:nth-child(1) .ce-block__content > .ce-paragraph {
    font-family: "Poppins", arial, sans-serif;
    margin-top: 30px !important;
    padding: 5px 25px;
}

/* Features - Supporting Content */

.theme-layout-default .layout--showcase-9 .feature .ce-block:not(:nth-child(1)) .ce-block__content {
    margin-top: 0;
    margin-bottom: 0;
    padding: 5px 25px;
}

.theme-layout-default .layout--showcase-9 .feature .ce-block:not(:nth-child(2)) .ce-block__content > .ce-paragraph {
    color: #919dab !important;
    font-family: "Poppins", arial, sans-serif;
}

/* Features - Buttons */

.theme-layout-default .layout--showcase-9 .feature .ce-block:last-child .ce-block__content > .buttons-tool {
    margin-bottom: -30px !important;
}

/* Lead Out */

.theme-layout-default .layout--showcase-9 .lead-out .ce-block__content > * {
    text-align: center !important;
}
#primary-nav { background: #fff; border-bottom: 1px solid #E6EAEE; color: #00797A; margin-bottom: 0; }
#primary-nav #app-title a { font-weight: 500; }
#primary-nav .navbar-brand a { color: #000; }
#primary-nav a { color: #7F8FA4; }
#primary-nav .navbar-brand { padding-left: 5px; overflow: hidden; text-overflow: ellipsis; text-shadow: none; white-space: nowrap; }
#primary-nav .navbar-toggle span { background-color: #4259ED; }
#primary-nav .navbar-brand a { text-decoration: none; }
#primary-nav .section { color: #000; margin-left: 15px; }
#primary-nav .nav a { color: #7F8FA4; font-size: 14px; text-shadow: none; text-transform: none; }
#primary-nav .navbar-toggle { background: transparent; border: 0; top: 15px; }
#primary-nav .navbar-toggle span { background-color: #7F8FA4; }
#primary-nav .navbar-collapse { float: right; }
#primary-nav .navbar-collapse.in { background: transparent; margin-top: 30px; }
#primary-nav .navbar-collapse li a { text-align: center; }
#primary-nav #user-nav .nav { margin-right: -20px; }

@media screen and (max-width: 1200px) {
    #primary-nav { min-height: 65px; }
    #primary-nav .navbar-brand { left: 0; top: 8px; position: absolute; margin: 0; padding: 15px 30px; text-align: center; width: 100%; }
    #primary-nav #app-title { display: none; margin: 0 10px; }
    #primary-nav #environment { display: none; }
    #primary-nav .section { margin: 0; }
    #primary-nav .navbar-collapse { float: none; margin: 30px -15px 0 -15px; }
    #primary-nav .navbar-collapse.in { border-top: 1px solid #e7e7e7; }
    #primary-nav #user-nav .nav { margin-right: 0; margin-left: 0; }
}

@media screen and (max-width: 768px) {
    #primary-nav .navbar-collapse.in { background: #FAFBFC; }
}

@media screen and (min-width: 1201px) {
    #primary-nav .nav > li:last-child > a { padding-right: 5px; }
}

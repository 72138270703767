.theme-layout-default .layout--content-10 > div {
    padding: 80px 0;
}

/* Description */

.theme-layout-default .layout--content-10 .primary .ce-block .ce-block__content > .ce-paragraph {
    color: #919dab;
    font-family: "Poppins", arial, sans-serif;
    font-size: 18px;
    line-height: 28px;
}


/* Date - maybe legacy and not used? */

.date-field { background: url('../images/select-arrow.png') no-repeat 100% 50% #fff; border: 1px solid #E6EAEE; padding: 6px 32px 6px 10px; -moz-appearance: none; -webkit-appearance: none; appearance: none; -moz-border-radius: 3px; -webkit-border-radius: 3px; border-radius: 4px; -moz-appearance: none; text-indent: 0.01px; text-overflow: ''; }
.date-field:focus { outline: 0; border: 1px solid #1A91EB; }
.date-field:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }

/* Date Picker */

.react-datepicker { border-color: #DFE6F4; font-family: arial, helvetica, sans-serif; font-size: 11px; }
.react-datepicker__header {border-bottom: 1px solid #DFE6F4; background: #F6F9FC; }
.react-datepicker__current-month { color: #333; font-size: 1em; }
.react-datepicker__navigation--previous { top: 11px; }
.react-datepicker__navigation--next { top: 11px; }
.form-inline .react-datepicker__navigation--previous { min-width: unset; }
.form-inline .react-datepicker__navigation--next { min-width: unset; }
.react-datepicker__day-name, .react-datepicker__day { color: #434a54; margin: 2px 5px; line-height: 25px; height: 25px; width: 25px; }
.react-datepicker__day--keyboard-selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__day--selected { background-color: #ccc; }
.react-datepicker__day--today { }
.react-datepicker-popper { z-index: 10; }
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle { border-bottom-color: #F6F9FF; }
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before { border-bottom-color: #DFE6F4; }
.react-datepicker__header { background: #F6F9FF; border-color: #DFE6F4; }
.react-datepicker__day:hover { background: #DFE6F4; }
.react-datepicker__day--selected,  .react-datepicker__day--selected:hover { background: #DFE6F4; }

.col-md-3 > .form-group > div > .react-datepicker-wrapper, .col-md-3 > .form-group > div > .react-datepicker-wrapper > .react-datepicker__input-container { width: 100%; }
.col-md-4 > .form-group > div > .react-datepicker-wrapper, .col-md-4 > .form-group > div > .react-datepicker-wrapper > .react-datepicker__input-container { width: 100%; }
.col-md-6 > .form-group > div > .react-datepicker-wrapper, .col-md-6 > .form-group > div > .react-datepicker-wrapper > .react-datepicker__input-container { width: 100%; }


/* Container */

.help-tooltip {
    font-size: 13px;
}

.help-tooltip.in {
    opacity: 1.0;
}

/* Placement */

.help-tooltip.top .tooltip-arrow {
    border-top-color: #2F3C4E;
}

.help-tooltip.bottom .tooltip-arrow {
    border-bottom-color: #2F3C4E;
}

.help-tooltip.left .tooltip-arrow {
    border-left-color: #2F3C4E;
}

.help-tooltip.right .tooltip-arrow {
    border-right-color: #2F3C4E;
}

/* Inner */

.help-tooltip .tooltip-inner {
    background-color: #2F3C4E;
    color: #fff;
    padding: 12px 20px;
    -moz-box-shadow: 0 0 5px #999;
    -webkit-box-shadow: 0 0 5px #999;
    box-shadow: 0 0 5px #999;
}

.help-tooltip.left .tooltip-inner {
    text-align: right;
}

.help-tooltip.right .tooltip-inner {
    text-align: left;
}
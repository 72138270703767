.panel-body > .form-group:last-child { margin-bottom: 5px; }
.panel-body > .form-group.form-group-group:last-child { margin-bottom: 0; }
/*.panel-body > div:last-child > div:last-child > .form-group:last-child { margin-bottom: 5px; }*/
/*.panel-body > .row:last-child > div:last-child > .form-group { margin-bottom: 5px; }*/
.panel-body > div:not(.row):not(.form-section):not(:last-child) > .row:last-child > div:last-child > .form-group { margin-bottom: 0; }
.panel-body > div:not(.row):not(.form-section):last-child > .row:last-child > div:last-child > .form-group { margin-bottom: 5px; }
.panel-body div.divider { border-top: 1px solid #E6EAEE; margin: 10px -35px 0; padding: 0 0 30px 0; }
.panel-body .form-section > div.divider { margin-left: -30px; margin-right: -30px; }
.panel-body div > div.divider:first-child { margin-top: 35px; }
.panel-body h4.divider { border-top: 1px solid #E6EAEE; margin: 35px -35px 0; padding: 35px 35px 30px; }
.panel-body .row + h4.divider { margin-top: 10px; }
.panel-body > h4:first-child { margin-top: 0; }
.panel-body h4.top { margin-top: 0; }
.panel-body div.row.fields + h3 { margin-top: 10px; }
.panel-body div.row.fields + h4 { margin-top: 10px; }
.panel-body div.row.fields + h4.divider { margin-top: 10px; }
.panel-body div.row.fields + div > div.divider:first-child { margin-top: 10px; }

.panel.padded { padding: 20px 20px; }
.panel .actions { margin-bottom: 0; }
.panel-default { border-color: #E6EAEE; }

.panel-form { background: #fefefe; border: 1px solid #E6EAEE; margin-bottom: 20px; padding-top: 10px; padding-bottom: 10px; -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .3); -moz-box-shadow: 0 0 3px rgba(0, 0, 0, .3); box-shadow: 0 0 3px rgba(0, 0, 0, .3); }

.panel-body > h1:first-child, .panel-body > h2:first-child, .panel-body > h3:first-child, .panel-body > h4:first-child, .panel-body > h5:first-child, .panel-body > h6:first-child, .panel-body > p:first-child, .panel-body > ul:first-child, .panel-body > ol:first-child, .panel-body > ul:first-child > li:first-child, .panel-body > ol:first-child > li:first-child { margin-top: 0 !important; }
.panel-body > h1:last-child, .panel-body > h2:last-child, .panel-body > h3:last-child, .panel-body > h4:last-child, .panel-body > h5:last-child, .panel-body > h6:last-child, .panel-body > p:last-child, .panel-body > ul:last-child, .panel-body > ol:last-child, .panel-body > ul:last-child li:last-child, .panel-body > ol:last-child li:last-child, .panel-body > .form-group:last-child > .read-only > :last-child { margin-bottom: 0 !important; }

/* Responsive */

@media screen and (max-width: 991px) {
    .panel-body > .row:last-child > div[class*="col-md-"]:last-child > .form-group,
    .panel-body > .row:last-child > div[class*="col-xs-"] > .form-group {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 992px) {
    .panel-body > .row:last-child > div[class*="col-md-"] > .form-group,
    .panel-body > .row:last-child > div[class*="col-xs-"] > .form-group {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 1201px) {
    .panel-body > .row:last-child > div[class*="col-md-"] > .form-group,
    .panel-body > .row:last-child > div[class*="col-xs-"] > .form-group {
        margin-bottom: 0;
    }
}
.btn {
    border: 0;
    padding: 10px 18px;
    text-shadow: none;
    text-transform: none;
}

.btn:focus,
.btn:active:focus {
    outline: none;
}

.btn .fa {
    color: rgba(255, 255, 255, 0.75);
    font-size: 12px;;
}

.btn .fa.left {
    margin: 0 8px 0 0;
}

.btn .fa.right {
    margin: 0 0 0 10px !important;
}

/* Extra Small */

.btn-xs {
    font-size: 11px;
    padding: 3px 12px 3px 12px;
}

.btn-xs .fa {
    font-size: 10px;
}

.btn-xs .fa.left {
    margin-right: 7px !important;
}

.btn.icon-only .fa.left {
    margin-right: 0 !important;
}

.btn-xs .fa.right {
    margin-left: 7px !important;
}

.btn.icon-only .fa.right {
    margin-left: 0 !important;
}

/* Small */

.btn-sm {
    font-weight: 500;
    padding: 6px 20px 5px 20px;
}

.btn-sm .fa {
    font-size: 12px;
}

/* Medium */

.btn-md {
}

.btn-md .fa {
}

/* Large */

.btn-lg {
    font-size: 15px;
    font-weight: 500;
    padding: 13px 27px;
}

.btn-lg .fa {
    font-size: 14px;
    margin: 5px 0 0 12px;
    padding: 0 0 0 0;
}

.btn-lg .fa:after {
    content: " ";
}

.btn.big {
    font-size: 25px;
    height: 57px;
    padding: 0 20px;
}

/* Block */

.btn.block {
    width: 100%;
}

/* Default */

.btn-default,
.btn-default.active,
.btn-default.disabled,
.btn-default[disabled],
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default[disabled]:hover {
    background: rgba(51, 94, 234, 0.1);
    color: #1A91EB;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.active:hover,
.btn-default:active:hover,
.btn-default:active:focus {
    background: rgba(51, 94, 234, 0.2);
    color: #1A91EB;
}

.btn.btn-default .fa {
    color: #1A91EB;
}

/* Primary */

.btn-primary,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
    background-color: #1A91EB;
    border-color: #1A91EB;
}

/* Link */

.btn.btn-link .fa {
    color: #1A91EB;
}

/* Important */

.btn.important {
    color: #1A91EB !important;
    border-color: #1A91EB !important;
}


/* Responsive */

@media screen and (max-width: 768px) {

    .buttons .list.blocks.horizontal {
        display: block;
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .buttons .list.blocks.horizontal .block-btn {
        display: block;
        width: 100%;
    }

    .buttons .list.blocks.horizontal .block-btn {
        display: block;
        width: 100%;
    }

}

/* Reset and Theme */

html, body { }

body { background: #F1F5F9; color: #434a54; font-family: arial, helvetica, sans-serif; }
#environment { display: none; margin-left: 10px; }
/*#environment.localhost { display: inline; }*/

.user-select-none { user-select: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; }

/* Page */

#page { background: #FAFBFC; }

/* Main Content */

#main-content { margin-top: 60px; padding-bottom: 60px; }
@media screen and (max-width: 768px) {
    #main-content { margin: 60px 0 0 0; }
}

@media screen and (min-width: 1200px) {
    #page.fixed-navs #primary-nav { position: fixed; top: 0; width: 100%; z-index: 1000; }
    #page.fixed-navs #secondary-nav { position: fixed; top: 52px; width: 100%; z-index: 1000; }
    #page.fixed-navs #main-content { margin-top: 102px; padding-top: 60px; padding-bottom: 60px; }
}

/* Headings, Paragraphs, Lists */

.h1, h1 { color: #1A91EB; font-weight: normal; font-size: 26px; margin-top: 20px; margin-bottom: 10px; text-align: left; }
.h1.title, h1.title { margin-top: 0; }
@media screen and (max-width: 768px) {
    .h1, h1 { text-align: center; }
}
.h2, .h3, .h4, .h5, .h6, h2, h3, h4, h5, h6 { color: #000; font-weight: normal; margin-top: 20px; margin-bottom: 20px; }
.h2, h2 { font-size: 26px; }
.h3, h3 { padding-top: 6px; }
.h1 span.support, .h2 span.support, .h3 span.support, .h4 span.support, .h5 span.support, .h6 span.support, h1 span.support, h2 span.support, h3 span.support, h4 span.support, h5 span.support, h6 span.support { border-left: 1px solid #7F8FA4; font-size: 15px; font-weight: 200; margin-left: 5px; padding-left: 15px; position: relative; top: -2px; }
.h1 span.pull-right, .h2 span.pull-right, .h3 span.pull-right, .h4 span.pull-right, .h5 span.pull-right, .h6 span.pull-right, h1 span.pull-right, h2 span.pull-right, h3 span.pull-right, h4 span.pull-right, h5 span.pull-right, h6 span.pull-right { margin-top: -6px; }

@media screen and (max-width: 1199px) {
    .h1 span.pull-right, .h2 span.pull-right, .h3 span.pull-right, .h4 span.pull-right, .h5 span.pull-right, .h6 span.pull-right, h1 span.pull-right, h2 span.pull-right, h3 span.pull-right, h4 span.pull-right, h5 span.pull-right, h6 span.pull-right { margin-top: 20px; }
}

@media screen and (max-width: 1199px) {
    h3 span.pull-right { display: block; float: none!important; margin-top: 20px; }
    h3 span.pull-right * { width: 100%; }
}

p { margin: 0 0 20px 0; }
hr { margin: 60px 0 58px 0; }
ol:not(.nav):not(.list-group):not(.pagination):not(.dropdown-menu):not(.custom), ul:not(.nav):not(.list-group):not(.pagination):not(.dropdown-menu):not(.custom) { margin: 20px 0 20px 0; padding: 0; }
ol:not(.nav):not(.list-group):not(.pagination):not(.dropdown-menu):not(.custom) > li:not(.link):not(.custom), ul:not(.nav):not(.list-group):not(.pagination):not(.dropdown-menu):not(.custom) > li:not(.link):not(.custom) { margin: 0 0 10px 20px; padding: 0 0 0 20px; }
ol:not(.nav):not(.list-group):not(.pagination):not(.dropdown-menu):not(.custom) > li:not(.link):not(.custom) { list-style-type: decimal; }
ul:not(.nav):not(.list-group):not(.pagination):not(.dropdown-menu):not(.custom) > li:not(.link):not(.custom) { list-style-type: disc; }
.todo { color: #cc0000; }
img.max-width { width: 100%; }
.text-muted, .name-values .table > thead > tr > th.value.text-muted, .name-values .table > thead > tr > th.name.text-muted { color: #7F8FA4; }
.text-break-word { word-wrap: break-word; }
.label { padding: .3em .6em .3em; }
.invisible { visibility: hidden; }
.visible { visibility: visible; }
.block { display: block; }
.inline { display: inline; }
.inline-block { display: inline-block; }
.absolute { position: absolute; }
.fixed { position: fixed; }
.relative { position: relative; }
.static { position: static; }
.marginLeftRightAuto { margin-left: auto; margin-right: auto; }
.spacer { height: 60px; }
blockquote { font-style: italic; font-size: 16px; }
a { color: #1A91EB; }
a:focus, button:focus, .btn:focus { outline: 0; }
.clickable { cursor: pointer; }
.non-clickable { cursor: default; }
.muted { color: #999; }
.error { color: #c00; }

@media screen and (max-width: 992px) {
    .hide-sm { display: none; }
}

@media screen and (min-width: 1200px) {
    .pull-right-lg { float: right!important; }
    .hide-lg { display: none; }
}

@media screen and (min-width: 993px) and (max-width: 1199px) {
    .pull-right-md { float:right!important; }
}

@media screen and (max-width: 1199px) {
    .show-lg { display: none; }
    .text-center-md { text-align: center; }
}

@media screen and (max-width: 992px) {
    .text-left-sm { text-align: left; }
}

@media screen and (max-width: 992px) {
    .text-center-sm { text-align: center; }
}

@media (max-width: 768px) {
    .text-center-xs { text-align: center; }
}

.lead { color: #7F8FA4; font-size: 21px; margin-bottom: 2em; text-align: left; }
.lead.text-center { text-align: center; }
@media screen and (max-width: 768px) {
    .lead { margin-bottom: 2em; text-align: center; }
}

#loading-bar { background: #1A91EB; position: fixed; height: 4px; top: 0; z-index: 9999; }

.header { border-bottom: 1px solid #e7e7e7; min-height: 93px; }
.header .logo { margin: 26px 0 10px 0; position: absolute; height: 30px; }

.shadowed-normal { -moz-box-shadow: 0 0 15px #EBEDF1; -webkit-box-shadow: 0 0 15px #EBEDF1; box-shadow: 0 0 15px #EBEDF1; }
.inverted .shadowed-normal { -moz-box-shadow: 0 0 15px #999; -webkit-box-shadow: 0 0 15px #999; box-shadow: 0 0 15px #999; }

/* Bootbox */

.bootbox .modal-content { padding-top: 20px; }
.bootbox .bootbox-body { color: #ccc; font-size: 20px; padding: 25px 0 3px 0; text-align: center; }

/* App Logo */

.app-logo { margin-bottom: 60px; text-align: center; }
.app-logo .fa { background: #1A91EB; color: #fff; font-size: 20px; line-height: 60px; height: 60px; width: 60px; -webkit-border-radius: 30px; }

.dialog-panel { text-align: center; max-width: 488px; margin-left: auto; margin-right: auto; }
.dialog-panel label { display: block; text-align: left; }
.dialog-panel .help-block { display: block; text-align: left; }
.dialog-panel .help-block.error { display: table; }
.dialog-panel-body { padding: 20px 45px; }
.dialog-panel .panel-body h1, .dialog-panel .panel-body .h1 { text-align: center; }
.dialog-panel .panel-body .lead { margin-bottom: 60px; text-align: center; }
.dialog-panel .actions { margin: 60px 0 30px 0; }
.dialog-panel .actions button, .dialog-panel .actions a.btn { display: block; margin-left: 0; margin-right: 0; margin-bottom: 15px; width: 100%; }

.dialog-panel .tabbed-pills-container { max-width: 486px; margin: -35px -60px 60px -60px; }
.dialog-panel .tabbed-pills-container > ul > li > a { background: #F5F8FA; color: #C5CEDB; padding: 13px 0; border-radius: 0; }
.dialog-panel .tabbed-pills-container > ul > li:first-child > a { border-radius: 3px 0 0 0; }
.dialog-panel .tabbed-pills-container > ul > li:last-child > a { border-radius: 0 3px 0 0; }
.dialog-panel .tabbed-pills-container > ul > li.active > a { background: #EAF0F4; color: #7F8FA4; }
.dialog-panel .tabbed-pills-container > ul > li > a:hover { color: #7F8FA4; }
.dialog-panel .tabbed-pills-container .fa { display: block; font-size: 28px; margin-bottom: 10px; }

@media screen and (max-width: 768px) {
    .dialog-panel .tabbed-pills-container { max-width: 100%; margin-left: 0; margin-right: 0; }
}

/* List */

.list.sortable {
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.list > div .delete {
    border: 0;
    position: absolute;
    margin: 0 !important;
    padding: 5px !important;
    top: 0;
    right: 0;
    font-size: 10px;
    overflow: visible;
    text-align: center;
    text-transform: uppercase;
    visibility: hidden;
    z-index: 52;
}

.list > div:hover .delete {
    visibility: visible;
}

.list > div .delete .fa {
    background: #f0f0f0;
    color: #000;
    cursor: pointer;
    display: block;
    opacity: 1.0;
    height: 20px;
    width: 20px;
    line-height: 18px;
    text-decoration: none;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.list > div .delete:hover .fa {
    color: #000;
    opacity: 1.0;
}

.list > div .delete:hover .fa:hover {
    background: #c00;
    color: #fff;
}

.list.blocks:not(.sorting) > .block .prepend:hover:before, .list.blocks:not(.sorting) > .block .append:hover:before {
    visibility: visible !important;
}

.list.blocks:not(.sorting) > .block .prepend:hover .fa, .list.blocks:not(.sorting) > .block .append:hover .fa {
    visibility: visible !important;;
}

.list.blocks > .block > .drag-handle {
    background: #1A91EB;
    border: 1px solid #fff;
    top: -8px;
    left: 50%;
    margin-left: -8px;
    content: '';
    position: absolute;
    visibility: hidden;
    width: 15px;
    height: 15px;
    z-index: 100;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
}

.list.blocks:not(.sorting) > .block:hover > .drag-handle, .list.blocks > .block.sort-helper > .drag-handle {
    visibility: visible;
}

/* List - blocks (vertical) */

.list.blocks.vertical {
}

.list.blocks.vertical .block {
    position: relative;
}

.list.blocks.vertical .block .prepend, .list.blocks.vertical .block .append {
    height: 8px;
    left: 0;
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 10;
}

.list.blocks.vertical .block .prepend:before, .list.blocks.vertical .block .append:before {
    border-top: 1px dashed #1A91EB;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    visibility: hidden;
    width: 100%;
    z-index: 1;
}

.list.blocks.vertical .block .prepend {
    top: -5px;
}

.list.blocks.vertical .block .prepend:before {
    top: 4px;
}

.list.blocks.vertical .block .append {
    bottom: -4px;
}

.list.blocks.vertical .block .append:before {
    bottom: 3px;
}

.list.blocks.vertical .block .prepend .fa, .list.blocks.vertical .block .append .fa {
    color: #1A91EB;
    font-size: 11px;
    padding: 0 10px;
    position: relative;
    visibility: hidden;
    width: 8px;
    z-index: 2;
}

.list.blocks.vertical .block .prepend .fa.left, .list.blocks.vertical .block .append .fa.left {
    left: -50%;
    top: -6px;
}

.list.blocks.vertical .block .prepend .fa.right, .list.blocks.vertical .block .append .fa.right {
    right: calc(-50% + 8px);
    top: -6px;
}

/* List - blocks (horizontal) */

.list.blocks.horizontal {
}

.list.blocks.horizontal:after {
    clear: both;
    content: '';
    display: block;
}

.list.blocks.horizontal .block {
    position: relative;
    display: inline-block;
}

.list.blocks.horizontal .block .prepend, .list.blocks.horizontal .block .append {
    height: 100%;
    left: 0;
    position: absolute;
    width: 8px;
    text-align: center;
    z-index: 10;
}

.list.blocks.horizontal .block .prepend:before, .list.blocks.horizontal .block .append:before {
    border-left: 1px dashed #1A91EB;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    visibility: hidden;
    width: 1px;
    z-index: 1;
}

.list.blocks.horizontal .block .prepend {
    left: -4px;
}

.list.blocks.horizontal .block .prepend:before {
    left: 3px;
}

.list.blocks.horizontal .block .append {
    right: -5px;
    left: unset;
    top: 0;
}

.list.blocks.horizontal .block .append:before {
    right: 4px;
    left: unset;
}

.list.blocks.horizontal .block .prepend .fa, .list.blocks.horizontal .block .append .fa {
    color: #1A91EB;
    font-size: 11px;
    padding: 10px 0;
    position: absolute;
    visibility: hidden;
    width: 8px;
    z-index: 2;
}

.list.blocks.horizontal .block .prepend .fa.top, .list.blocks.horizontal .block .append .fa.top {
    left: -2px;
    top: -22px;
}

.list.blocks.horizontal .block .prepend .fa.bottom, .list.blocks.horizontal .block .append .fa.bottom {
    left: -2px;
    bottom: -22px;
}

/* List - blocks (grid) */

.list.blocks.grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    text-align: center;
}

.list.blocks.grid > .block {
    margin-bottom: 30px;
}

.list.grid > .block > .form-group-input-inline-editor {
    height: 100%;
    margin: 0 15px;
}

.list.blocks.grid-2 > .block {
    width: 50%;
}

.list.blocks.grid-3 > .block {
    width: 33.3333%;
}

.list.blocks.grid-4 > .block {
    width: 25%;
}

.list.blocks.grid-5 > .block {
    width: 20%;
}

.list.blocks.grid-6 > .block {
    width: 16.6666%;
}

@media screen and (max-width: 992px) {

    .list.blocks.grid-3,
    .list.blocks.grid-4,
    .list.blocks.grid-5,
    .list.blocks.grid-6 {
        display: block;
        flex-wrap: unset;
    }

    .list.blocks.grid-3 > .block,
    .list.blocks.grid-4 > .block,
    .list.blocks.grid-5 > .block,
    .list.blocks.grid-6 > .block {
        display: block !important;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 450px;
    }

}

@media screen and (max-width: 768px) {

    .list.blocks.grid-2 {
        display: block;
        flex-wrap: unset;
    }

    .list.blocks.grid-2 > .block {
        display: block !important;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 450px;
    }

}

/* for testing only */

/*.list.blocks .block .prepend { background: rgba(0, 0, 0, 0.05); }*/
/*.list.blocks .block .append { background: rgba(0, 0, 0, 0.3); }*/
/*.list.blocks .block .prepend:before, .list.blocks .block .append:before { visibility: visible; }*/
/*.list.blocks .block .prepend .fa, .list.blocks .block .append .fa { visibility: visible; }*/

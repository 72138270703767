div.input-inline-editor { padding: 30px 0; height: auto; }
div.input-inline-editor .form-group-input-inline-editor { margin: 20px 0; }
.form-group > div.input-inline-editor { padding-bottom: 25px; }
div.input-inline-editor .codex-editor .ce-toolbar .ce-toolbox { background: #fff; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; -moz-box-shadow: 0 0 5px rgba(0,0,0,0.1); -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.1); box-shadow: 0 0 5px rgba(0,0,0,0.1); }
div.input-inline-editor .codex-editor .codex-editor__redactor { padding-bottom: 5px; }
div.input-inline-editor .codex-editor .ce-block__content { max-width: 100%; margin: 0 50px 0 50px; }
div.input-inline-editor .codex-editor .ce-block--focused { background-image: none; }
div.input-inline-editor[disabled] { display: table; position: relative; }
div.input-inline-editor[disabled] .disabled-overlay { display: table-cell; width: 100%; height: 100%; position: absolute; z-index: 2; }
div.input-inline-editor[disabled].form-control .disabled-overlay { width: 100%; height: 100%; top: 0; left: 0; }

div.input-inline-editor .ce-inline-toolbar--left-oriented { text-align: left; }
div.input-inline-editor .ce-inline-toolbar .ce-inline-toolbar__dropdown{ white-space: nowrap; }

div.input-inline-editor .ce-toolbar { padding: 0 0; margin-left: 40px; top: 0; }
@media not all and (min-resolution:.001dpcm) { @media { div.input-inline-editor .ce-toolbar { margin-top: -2px; } } }
div.input-inline-editor .ce-toolbar.ce-toolbar--opened { z-index: 55; }
div.input-inline-editor .ce-toolbar__content { max-width: 100%; margin-right: 50px; }
div.input-inline-editor .ce-toolbar__plus { left: -32px; top: 0; }
div.input-inline-editor .ce-toolbar__plus svg { width: 8px; height: 8px; }
div.input-inline-editor .ce-toolbox { margin-left: 0; }
div.input-inline-editor .ce-toolbar__actions { right: -8px }
div.input-inline-editor .ce-toolbar__actions-buttons { margin-top: -18px; margin-right: -37px; padding: 5px; text-align: center; }
@media not all and (min-resolution:.001dpcm) { @media { div.input-inline-editor .ce-toolbar__actions-buttons { margin-top: -16px; } } }
@media (max-width: 650px) { div.input-inline-editor .ce-toolbar__actions-buttons { margin-top: -5px; } }

div.input-inline-editor .ce-toolbar__actions-buttons .ce-toolbar__settings-btn { background: transparent; width: 34px; height: 34px; }
div.input-inline-editor .codex-editor .ce-toolbar__actions .ce-toolbar__settings-btn svg { margin-top: 0; }

div.input-inline-editor .ce-settings { top: 23px; right: -31px; }
div.input-inline-editor .ce-settings > div { margin-right: -5px; }
div.input-inline-editor .ce-settings .cdx-settings-button:nth-child(3n+1) { margin-left: -1px; }
div.input-inline-editor .ce-settings .ce-settings__button:nth-child(3n+1) { margin-left: -1px; }

/* uncomment for debugging */
/*div.input-inline-editor .ce-toolbar--opened { background: #ccc; min-height: 1px; }*/
/*div.input-inline-editor .ce-toolbar__plus { background: #ccc; }*/
/*div.input-inline-editor .ce-toolbar__actions-buttons { background: #ccc; }*/

div.input-inline-editor .ce-inline-toolbar.ce-inline-toolbar--showed { z-index: 55; }

div.input-inline-editor .ce-conversion-toolbar.ce-conversion-toolbar--showed { z-index: 55; }

div.input-inline-editor .cdx-block { padding: 0.7em 0; }

div.input-inline-editor .ce-block:first-child h1,
div.input-inline-editor .ce-block:first-child h2,
div.input-inline-editor .ce-block:first-child h3,
div.input-inline-editor .ce-block:first-child h4,
div.input-inline-editor .ce-block:first-child h5,
div.input-inline-editor .ce-block:first-child h6,
div.input-inline-editor .ce-block:first-child ul,
div.input-inline-editor .ce-block:first-child ol,
div.input-inline-editor[disabled] .ce-block:nth-child(2) h1,
div.input-inline-editor[disabled] .ce-block:nth-child(2) h2,
div.input-inline-editor[disabled] .ce-block:nth-child(2) h3,
div.input-inline-editor[disabled] .ce-block:nth-child(2) h4,
div.input-inline-editor[disabled] .ce-block:nth-child(2) h5,
div.input-inline-editor[disabled] .ce-block:nth-child(2) h6,
div.input-inline-editor[disabled] .ce-block:nth-child(2) ul,
div.input-inline-editor[disabled] .ce-block:nth-child(2) ol { margin-top: 0 !important; padding-top: 0 !important; }

div.input-inline-editor.read-only > :last-child { margin-bottom: 0 !important; }

div.input-inline-editor .ce-block:last-child .buttons { margin-bottom: 10px; }
div.input-inline-editor .buttons { }
div.input-inline-editor .buttons .form-group .blocks { display: inline-block; margin: 0 auto; }
div.input-inline-editor .buttons .form-group .blocks .block {  margin-bottom: 20px; }
div.input-inline-editor .buttons .button-wrap { display: inline-block; position: relative; margin-left: 10px; margin-right: 10px; }
div.input-inline-editor .buttons .blocks { margin-left: -10px !important; margin-right: -10px !important; }
div.input-inline-editor .buttons .button-wrap .ce-inline-toolbar { margin: 0 !important; top: unset; bottom: -45px; left: 50%; transform: translateX(-50%); }
div.input-inline-editor .buttons .button-wrap .btn > div { min-width: 10px; }
div.input-inline-editor .buttons .button-wrap .btn *[contenteditable="true"]:focus { outline: none; }
div.input-inline-editor .buttons .block .delete { visibility: hidden; }
div.input-inline-editor .buttons .block:hover .delete { visibility: visible; }
div.input-inline-editor .buttons .ce-inline-toolbar { color: #555555 !important; }
div.input-inline-editor .buttons .ce-inline-toolbar svg { fill: #555555; }
div.input-inline-editor .buttons .ce-inline-toolbar .ce-inline-toolbar__actions { border: 1px solid rgba(201, 201, 204, .48); position: absolute; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; -moz-box-shadow: 0 3px 15px -3px rgba(13,20,33,.13); -webkit-box-shadow: 0 3px 15px -3px rgba(13,20,33,.13); box-shadow: 0 3px 15px -3px rgba(13,20,33,.13); }
div.input-inline-editor .buttons .ce-inline-toolbar .ce-inline-toolbar__actions input[type="text"] { border: 0; min-width: 280px; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; }
div.input-inline-editor .buttons .ce-inline-toolbar .ce-conversion-toolbar .ce-conversion-tool__icon .btn { padding: 0; width: 100%; height: 100%; -moz-border-radius: 2px; -webkit-border-radius: 2px; border-radius: 2px; -moz-box-shadow: 0 0; -webkit-box-shadow: 0 0; box-shadow: 0 0; }
div.input-inline-editor .buttons .ce-inline-toolbar .ce-conversion-toolbar .ce-conversion-tool__icon_text { font-size: 8px; }

div.input-inline-editor .cdx-checklist__item { padding: 0; }
div.input-inline-editor .cdx-checklist__item .cdx-checklist__item-checkbox { margin-top: 1px; margin-right: 20px; }
div.input-inline-editor .cdx-checklist__item .cdx-checklist__item-text { padding: 1px 0 5px 0; }
div.input-inline-editor .cdx-checklist__item--checked .cdx-checklist__item-checkbox { background: #1A91EB; border-color: #1A91EB; }

div.input-inline-editor.read-only .code { margin: 10px 0; }
div.input-inline-editor.read-only .code > div { min-height: auto; padding: 10px; resize: none; }

div.input-inline-editor .embed-tool .cdx-input.embed-tool__caption, div.input-inline-editor .ce-embed .caption { display: none; } /* 16/9 hack, breaks caption */
div.input-inline-editor .embed-tool, div.input-inline-editor .ce-embed { position: relative; padding-bottom: 58% !important; height: 0; } /* 16/9 hack, breaks caption */
div.input-inline-editor .embed-tool iframe, div.input-inline-editor .ce-embed iframe { position: absolute; left: 0; top: 0; width: 100%; height: 96%; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; } /* 16/9 hack, breaks caption */
div.input-inline-editor.read-only .embed { margin-bottom: 0; }
div.input-inline-editor.read-only .embed .caption { color: #ccc; font-style: italic; margin-top: 5px; }

div.input-inline-editor .ce-header { margin: 20px 0; padding: 0; }
div.input-inline-editor h1.ce-header { line-height: 1.3em; }
div.input-inline-editor h2.ce-header { line-height: 1.3em; }
div.input-inline-editor h3.ce-header { line-height: 1.3em; }
div.input-inline-editor h4.ce-header { line-height: 1.4em; }
div.input-inline-editor h5.ce-header { line-height: 1.5em; }
div.input-inline-editor h6.ce-header { line-height: 1.5em; }

div.input-inline-editor.read-only .ce-image .caption { color: #ccc; font-style: italic; }

div.input-inline-editor .cdx-list {  }
div.input-inline-editor .cdx-list li { margin-bottom: 10px !important; }
div.input-inline-editor ul.cdx-list--ordered li { list-style: decimal !important; }

div.input-inline-editor .cdx-block.ce-paragraph { margin: 1em 0; padding: 0; }
div.input-inline-editor .ce-paragraph:first-child { margin-top: 0; }

div.input-inline-editor .cdx-quote { margin: 20px 0; }
div.input-inline-editor .cdx-quote .cdx-input { border: 0; padding: 0 20px; box-shadow: none; }
div.input-inline-editor .cdx-quote__text { min-height: auto; }
div.input-inline-editor .cdx-quote__caption { color: #ccc; font-size: 14px; }

div.input-inline-editor .form-group-binary-images-with-upload { margin: 0 0 25px 0; padding-top: 10px; }
div.input-inline-editor .ce-block:first-child .form-group-binary-images-with-upload { margin-top: 0; padding-top: 0; }
div.input-inline-editor .cdx-block .form-group-binary-images-with-upload  { margin-top: 10px; margin-bottom: 10px; }

div.input-inline-editor .files-tool, div.input-inline-editor .cdx-block.files-tool { padding: 10px 0 0 0 !important; }
div.input-inline-editor .files-tool a { text-decoration: none; }
div.input-inline-editor .files-tool .form-group-binary-files-with-upload { margin-bottom: 20px; }

div.input-inline-editor .form-group-binary-files-with-upload { margin-bottom: 0; }
div.input-inline-editor .form-group-binary-files-with-upload .file-container { border: 1px solid #E6EAEE; margin-bottom: 10px; padding: 5px 10px 0 10px; position: relative; border-radius: 4px; }
div.input-inline-editor .form-group-binary-files-with-upload .file-container .file { margin-right: 40px }
div.input-inline-editor .form-group-binary-files-with-upload .file-container.openable:hover { background: #fafafa; cursor: pointer; }
div.input-inline-editor .form-group-binary-files-with-upload .file-container.openable:after { color: #E6EAEE; content: '\f054'; font-family: "FontAwesome"; font-size: 11px; top: 27px; right: 20px; position: absolute; }
div.input-inline-editor .form-group-binary-files-with-upload .file-container.openable:hover:after { color: #555; }
div.input-inline-editor .form-group-binary-files-with-upload .file-container-add:hover { background: #fafafa; }

div.input-inline-editor .codex-editor__redactor { }

div.input-inline-editor .ce-block--drop-target + .ce-block { margin-top: 12px !important; transition: margin-top 0.5s; }
div.input-inline-editor .ce-block--drop-target .ce-block__content { border-bottom: 1px dashed #1A91EB; margin-bottom: -1px; }
div.input-inline-editor .ce-block--drop-target .ce-block__content:before { border: none; bottom: 5px; color: #1A91EB; content: '\f054'; font-family: "FontAwesome"; font-size: 11px; height: 1px; left: -12px; top: unset; transform: none; width: 8px; }
div.input-inline-editor .ce-block--drop-target .ce-block__content:after { background: none; bottom: 5px; content: '\f053'; font-family: "FontAwesome"; font-size: 11px; top: unset; height: 1px; right: -12px; text-align: right; width: 8px; }

div.input-inline-editor .spacer { border: 1px dashed transparent; position: relative; height: unset; margin: 1em 0; text-align: center !important; }
div.input-inline-editor:hover .spacer { border-color: #ccc; }
div.form-group-input-inline-editor:hover .spacer { border-color: #ccc; }
div.input-inline-editor.read-only:hover .spacer { border-color: transparent; }
div.input-inline-editor .spacer .react-draggable { position: relative !important; margin: 0 0 -6px; }
div.form-group-input-inline-editor .spacer:hover .react-draggable:before { background: #1A91EB; border: 1px solid #fff; bottom: -8px; margin-left: -5px; content: ''; position: absolute; width: 15px; height: 15px; -moz-border-radius: 15px; -webkit-border-radius: 15px; border-radius: 15px; }

@media (min-width: 651px) {
    div.input-inline-editor .codex-editor--narrow .ce-toolbar__actions { right: 0; }
    div.input-inline-editor .codex-editor--narrow .ce-toolbar__plus { left: -30px; }
    div.input-inline-editor .codex-editor--narrow .codex-editor__redactor { margin-right: 0; }
    div.input-inline-editor .codex-editor .ce-block__content { margin-right: 50px; }
    div.input-inline-editor .codex-editor--narrow .ce-block--focused { margin-right: 0;padding-right: 0; }
}

@media (max-width: 650px) {
    div.input-inline-editor .ce-toolbar { margin-left: 40px; margin-right: 40px; top: 10px; }
    div.input-inline-editor .ce-toolbar__content { padding: 0; margin: 0; position: unset; }
    div.input-inline-editor .ce-toolbox { margin-left: 0; }
    div.input-inline-editor .ce-settings:before { bottom: -7px; }
    div.input-inline-editor .ce-toolbar__actions { right: 0; }
    div.input-inline-editor .ce-toolbar__actions-buttons { margin: 0; }
    div.input-inline-editor .ce-settings.ce-settings--opened { top: 50px; }
    div.input-inline-editor .ce-settings.ce-settings--opened .ce-settings__plugin-zone { -webkit-border-top-left-radius: 4px; -webkit-border-top-right-radius: 4px; -moz-border-top-left-radius: 4px; -moz-border-top-right-radius: 4px; border-top-left-radius: 4px; border-top-right-radius: 4px; }
    div.input-inline-editor .ce-settings.ce-settings--opened .ce-settings__default-zone { -webkit-border-bottom-left-radius: 4px; -webkit-border-bottom-right-radius: 4px; -moz-border-bottom-left-radius: 4px; -moz-border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; }
    div.input-inline-editor .ce-settings.ce-settings--opened .ce-settings__plugin-zone { background: #fff; margin-top: 0; min-height: 4px; }
    div.input-inline-editor .ce-settings.ce-settings--opened .ce-settings__default-zone { background: #fff; }
    div.input-inline-editor .ce-block--focused { margin: 0; padding: 0; }
}

.cdx-notifies .cdx-notify { padding-right: 40px; width: 320px; }
.cdx-notifies .cdx-notify__cross { right: 20px; top: 13px; }

/* Flushed */

.form-group-input-inline-editor.form-group-transparent.form-group-flushed { margin-left: -30px; margin-right: -30px; }

/* Flushed Exact */

.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor { position: unset; }

.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact { margin: 0; }
.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact:not(.read-only):before { content: ''; position: absolute; border: 1px dashed #ccc; left: 0; height: 100%; width: 100%; }
.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor { margin-left: -51px; margin-right: -51px; }

.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor .ce-toolbar { margin-left: 0; }
.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor .ce-toolbar__content {  }
.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor .ce-toolbar__content .ce-toolbar__plus { background: #fff; -moz-border-radius: 4px; -webkit-border-radiys: 4px; border-radius: 4px; }
.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor--toolbox-opened .ce-toolbar__content .ce-toolbar__plus { -moz-border-radius: 4px 0 0 4px; -webkit-border-radius: 4px 0 0 4px; border-radius: 4px 0 0 4px; }
.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor .ce-toolbar__content .ce-toolbox { margin-left: -2px; -moz-border-radius: 0 4px 4px 0; -webkit-border-radius: 0 4px 4px 0; border-radius: 0 4px 4px 0; }
.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor .ce-toolbar__content .ce-toolbox__button { width: 34px; height: 28px; }

.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor .ce-toolbar__actions { top: 0; right: -55px; }
.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor .ce-toolbar__actions .ce-toolbar__actions-buttons { background: #fff; padding: 0 0 0 0; margin-right: -35px; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; }
.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .ce-toolbar__actions-buttons { margin-top: 0; }
.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor .ce-toolbar__actions .ce-settings { }

.form-group-input-inline-editor.form-group-transparent.form-group-flushed-exact div.input-inline-editor .codex-editor .ce-inline-toolbar { margin-left: -35px; }

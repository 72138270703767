.theme-layout-default .layout--content-33 {
    padding: 80px 0;
}

.theme-layout-default .layout--content-33 .primary .ce-block__content > * {
    text-align: center !important;
}

/* Header */

.theme-layout-default .layout--content-33 .primary .ce-block:nth-child(1) .ce-block__content > .ce-header {
    margin-top: 0;
    margin-bottom: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

/* Description */

.theme-layout-default .layout--content-33 .primary .ce-block:nth-child(2) .ce-block__content > .ce-paragraph {
    color: #919dab;
    font-family: "Poppins", arial, sans-serif;
    font-size: 18px;
    line-height: 28px;
    margin-left: 50px;
    margin-right: 50px;
}

.theme-layout-default .layout--content-33 .primary .ce-block:nth-child(2) .ce-block__content > .ce-paragraph {
    margin-bottom: 50px;
}

/* Feature */

.theme-layout-default .layout--content-33 .row-features {
    margin-top: 50px !important;
    text-align: center;
}

.theme-layout-default .layout--content-33 .row-features .binary-images-with-upload .image-container {
    border: 2px solid rgba(0, 0, 0, 0.1);
    background: transparent;
}

.theme-layout-default .layout--content-33.has-background .row-features .binary-images-with-upload .image-container {
    border: 2px solid rgba(255, 255, 255, 0.3);
    background: transparent;
}

.theme-layout-default .layout--content-33 .row-features .binary-images-with-upload .image-container,
.theme-layout-default .layout--content-33 .row-features .binary-images-with-upload .image,
.theme-layout-default .layout--content-33 .row-features .binary-images-with-upload .image img,
.theme-layout-default .layout--content-33 .row-features .binary-images-with-upload .add {
    height: 120px;
}

.theme-layout-default .layout--content-33 .row-features .binary-images-with-upload .image img {
    object-fit: contain;
}

.theme-layout-default .layout--content-33.has-background .row-features .binary-images-with-upload .image-container img {
    filter: brightness(0) invert(1);
}

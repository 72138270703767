.theme-layout-default .layout--content-11 > div {
    padding: 450px 0 80px 0;
}

/* Description */

.theme-layout-default .layout--content-11 .primary .ce-block .ce-block__content > .ce-paragraph {
    font-family: "Poppins", arial, sans-serif;
    font-size: 18px;
    line-height: 28px;
}

.theme-layout-default .layout--content-16 > div {
    padding: 80px 0;
}

.theme-layout-default .layout--content-16 .primary .ce-block__content > * {
    text-align: center !important;
}

.theme-layout-default .layout--content-16 .primary .ce-block:last-child .ce-block__content > * {
    margin-top: 0;
    margin-bottom: 30px !important;
    padding-top: 0;
    padding-bottom: 0;
}

/* Features */

.theme-layout-default .layout--content-16 .row-features {
    margin-top: 30px !important;
}

.theme-layout-default .layout--content-16 .feature .ce-block:not(:first-child) .ce-block__content > .ce-header {
    margin-top: 40px;
}

.theme-layout-default .layout--content-16 .feature .ce-block .ce-block__content > .ce-paragraph {
    color: #919dab;
    font-family: "Poppins", arial, sans-serif;
    font-size: 18px;
    line-height: 28px;
}

/* Lead Out */

.theme-layout-default .layout--content-16 .row-lead-out {
    margin-top: 80px !important;
}

.theme-layout-default .layout--content-16 .lead-out .ce-block__content > * {
    text-align: center !important;
}

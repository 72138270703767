.theme-layout-default .layout--content-23 > div {
    padding: 20px 0;
}

.theme-layout-default .layout--content-23 .primary .ce-block__content,
.theme-layout-default .layout--content-23 .primary .ce-block__content > * {
    text-align: right !important;
}

.theme-layout-default .layout--content-23 .primary .ce-block:nth-child(1) .ce-block__content > .ce-header,
.theme-layout-default .layout--content-23 .primary .ce-block:nth-child(2) .ce-block__content > .ce-header {
    background: #000;
    display: inline-block;
    padding: 5px 14px;
    border-radius: 10px;
}

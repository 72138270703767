#developer-console { z-index: 20000; }
#developer-console .table th, #developer-console .table td { padding-top: 8px; padding-bottom: 8px; vertical-align: top; }
#developer-console .params { font-size: 10px; margin-left: 10px; }
#developer-console .fields { font-size: 10px; margin-left: 10px; }
#developer-console .log-level { display: inline-block; margin-right: 10px; min-width: 50px; }
#developer-console .heading { color: #ccc; font-weight: bold; margin: 0; }
#developer-console .debug { color: #666; }
#developer-console .info { color: #ccc; }
#developer-console .warn { color: #f90; }
#developer-console .error { color: #c00; }
#developer-console .request-status { margin-right: 10px; }
#developer-console .status-pending { color: #ccc; }
#developer-console .status-no-network { color: #c00; }
#developer-console .status-ok { }
#developer-console .status-bad-request { color: #c00; }
#developer-console .status-error { color: #c00; }
#developer-console .status-other { }
#developer-console .caller { float: right; }
#developer-console .react-json-view { margin-bottom: 10px; }

#developer-console .modal-content { background: rgb(43, 48, 59); }
#developer-console h1 { font-size: 26px; }
#developer-console h1, #developer-console h2, #developer-console h3, #developer-console h4, #developer-console h5 { color: #ccc; margin: 20px 0; }
#developer-console .panel-table, #developer-console .table, #developer-console th, #developer-console td { background: transparent; color: #ccc; }
#developer-console .panel-table, #developer-console th, #developer-console td { border-color: rgb(79, 91, 102); }
#developer-console tr:hover > td { background-color: rgb(79, 91, 102); }
#developer-console tr .clickable:hover { background-color: rgb(79, 91, 102); }
#developer-console tr.message-expanded:hover > td { background: transparent; }
#developer-console tr.message-expanded .clickable:hover { background: transparent; }
#developer-console tr.message-expanded > td:first-child { border-left: 5px solid rgb(79, 91, 102); padding-left: 17px; }
#developer-console tr.message-expanded:nth-child(even) > td:first-child { border-left-color: #ccc; }

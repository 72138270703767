/* Body */

body.mockup.wireframe {
    border: 1px solid #000;
}

/* Fragment */

div.mockup.wireframe.fragment {
    position: relative;
}

.container div.mockup.wireframe.fragment {
    cursor: pointer;
    transition: all .6s;
    margin: 30px -300px;
    transform: rotate(-0.5deg);
}

.container div.mockup.wireframe.fragment.expanded {
    /*transform: rotate(-0.5deg) scale(1.0);*/
}

.container div.mockup.wireframe.fragment > .row > div > .content {
    background: #fff;
    border-style: solid;
    border-width: 3px;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
    padding: 35px 25px;
    margin: 0;
    -moz-box-shadow: 0 0 20px #ccc;
    -webkit-box-shadow: 0 0 20px #ccc;
    box-shadow: 0 0 20px #ccc;
}

div.mockup.wireframe.fragment > .row > div > .content > :first-child,
div.mockup.wireframe.fragment > .row > div > .content > .row:first-child > * {
    margin-top: 0;
}

div.mockup.wireframe.fragment > .row > div > .content > :last-child,
div.mockup.wireframe.fragment > .row > div > .content > .row:last-child > *,
div.mockup.wireframe.fragment > .row > div > .content > .row:last-child > * > .form-group {
    margin-bottom: 0;
}

.container div.mockup.wireframe.fragment .requirement-notes {
    position: relative;
    top: -5px;
    z-index: 20;
}

.container div.mockup.wireframe.fragment .requirement-notes.left {
    margin-right: -40px;
}

.container div.mockup.wireframe.fragment .requirement-notes.right {
    margin-left: -40px;
}

.container div.mockup.wireframe.fragment .requirement-note {
    clear: both;
}

.container div.mockup.wireframe.fragment .requirement-note > div {
    background: #ffa;
    display: inline-block;
    position: relative;
    padding: 15px;
    min-width: 40px;
    text-align: center;
    -moz-box-shadow: 0 0 15px #dde8f4;
    -webkit-box-shadow: 0 0 15px #dde8f4;
    box-shadow: 0 0 15px #dde8f4;
}

.container div.mockup.wireframe.fragment .requirement-notes.left > .requirement-note > div {
    float: right;
}

.container div.mockup.wireframe.fragment .requirement-notes.right > .requirement-note > div {
    float: left;
}

.container div.mockup.wireframe.fragment .requirement-note.expanded > div {
    position: relative;
    padding: 20px;
    text-align: unset;
    -moz-box-shadow: 0 0 15px #dde8f4;
    -webkit-box-shadow: 0 0 15px #dde8f4;
    box-shadow: 0 0 15px #dde8f4;
}

.container div.mockup.wireframe.fragment .requirement-note + .requirement-note > div {
    margin-top: 10px;
}

.container div.mockup.wireframe.fragment .requirement-note > div > *:first-child {
    margin-top: 0;
}

.container div.mockup.wireframe.fragment .requirement-note > div > *:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 1750px) {

    .container div.mockup.wireframe.fragment {
        cursor: pointer;
        transition: all .6s;
        margin-left: 0;
        margin-right: 0;
        transform: rotate(-0.5deg) scale(1.0);
    }

    .container div.mockup.wireframe.fragment > .row > .col-xs-2:first-child {
        width: 0;
        left: -45px;
        position: absolute;
        z-index: 20;
    }

    .container div.mockup.wireframe.fragment > .row > .col-xs-2:last-child {
        width: 0;
        position: absolute;
        right: -45px;
    }

    .container div.mockup.wireframe.fragment > .row > .col-xs-8 {
        width: 100%;
    }

    .container div.mockup.wireframe.fragment .requirement-notes.left > .requirement-note.expanded {
        width: 300px;
        position: relative;
    }

    .container div.mockup.wireframe.fragment .requirement-notes.right > .requirement-note.expanded {
        left: -260px;
        width: 300px;
        position: relative;
    }

}

/* Common */

.mockup.wireframe,
.mockup.wireframe .nav,
.mockup.wireframe .input-select-enhanced-container > .input-select-enhanced > .__control,
.mockup.wireframe .input-select-enhanced-container > .input-select-enhanced > .__menu {
    font-family: "Comic Sans MS", Arial, sans-serif !important;
}

/* Primary Nav */

.mockup.wireframe #primary-nav {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
}

/* Secondary Nav */

.mockup.wireframe #secondary-nav {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
}

/* Footer */

.mockup.wireframe #footer-container {
    border-top-style: solid;
    border-top-width: 3px;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
}

.mockup.wireframe #footer-links {
    border-top-style: solid;
    border-top-width: 3px;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
}

/* Panel */

.mockup.wireframe .panel {
    border-style: solid;
    border-width: 10px;
    border-image: url("mockup/images/border.png") 10 10 stretch stretch !important;
}

/* Table */

.mockup.wireframe .table > thead > tr {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
    color: #000;
}

.mockup.wireframe .table > thead th {
    font-family: "Comic Sans MS", Arial, sans-serif !important;
}

/* Pagination */

.mockup.wireframe .pagination {
    border-style: solid;
    border-width: 1px;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
}

.mockup.wireframe .pagination li a {
    border-style: solid;
    border-width: 2px;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
}

/* Headings */

.mockup.wireframe .h1, .mockup.wireframe h1,
.mockup.wireframe .h2, .mockup.wireframe h2,
.mockup.wireframe .h3, .mockup.wireframe h3,
.mockup.wireframe .h4, .mockup.wireframe h4,
.mockup.wireframe .h5, .mockup.wireframe h5,
.mockup.wireframe .h5, .mockup.wireframe h6 {
    color: #000;
}

/* Divider */

.mockup.wireframe .panel-body div.divider {
    border-style: solid;
    border-top-width: 2px;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
}

/* Form */

.mockup.wireframe .lbl,
.mockup.wireframe label {
    font-family: "Comic Sans MS", Arial, sans-serif !important;
}

.mockup.wireframe input,
.mockup.wireframe input:focus,
.mockup.wireframe textarea,
.mockup.wireframe textarea:focus,
.mockup.wireframe .form-control:not(.input-inline-editor),
.mockup.wireframe .form-control:not(.input-inline-editor):focus,
.mockup.wireframe .input-group,
.mockup.wireframe .panel-table,
.mockup.wireframe .input-select-enhanced-container > .input-select-enhanced > .__control,
.mockup.wireframe .input-select-enhanced-container > .input-select-enhanced > .__control:focus,
.mockup.wireframe .binary-images-with-upload .add {
    background: #fff;
    border-style: solid;
    border-width: 3px;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
    font-family: "Comic Sans MS", Arial, sans-serif !important;
}

.mockup.wireframe .input-select-enhanced-container > .input-select-enhanced > div > .__value-container > .__single-value,
.mockup.wireframe .input-select-enhanced-container > .input-select-enhanced > div > .__value-container > .__single-value.__single-value--is-disabled {
    color: #999;
    font-family: "Comic Sans MS", Arial, sans-serif !important;
}

.mockup.wireframe .form-control.input-inline-editor,
.mockup.wireframe .form-control.input-inline-editor:focus {
    border-style: solid;
    border-width: 3px;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
    font-family: "Comic Sans MS", Arial, sans-serif !important;
}

.mockup.wireframe .form-control.input-inline-editor *,
.mockup.wireframe .form-control.input-inline-editor:focus * {
    font-family: "Comic Sans MS", Arial, sans-serif !important;
}

.mockup.wireframe .input-select-enhanced-container > .input-select-enhanced > .__menu div {
    border-style: solid;
    border-width: 1px;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
    font-family: "Comic Sans MS", Arial, sans-serif !important;
}

.mockup.wireframe .input-group input {
    border-width: 0;
}

.mockup.wireframe .__input input {
    border: 0 !important;
}

/* Button - Default */

.mockup.wireframe .btn-default,
.mockup.wireframe .btn-default.active,
.mockup.wireframe .btn-default:active,
.mockup.wireframe .btn-default:focus,
.mockup.wireframe .btn-default:active:focus,
.mockup.wireframe .btn-default.disabled,
.mockup.wireframe .btn-default.disabled:active,
.mockup.wireframe .btn-default.disabled:focus,
.mockup.wireframe .btn-default.disabled:hover,
.mockup.wireframe .btn-default[disabled],
.mockup.wireframe .btn-default[disabled]:active,
.mockup.wireframe .btn-default[disabled]:focus,
.mockup.wireframe .btn-default[disabled]:hover,
.mockup.wireframe .btn-primary,
.mockup.wireframe .btn-primary.active,
.mockup.wireframe .btn-primary:active,
.mockup.wireframe .btn-primary:focus,
.mockup.wireframe .btn-primary:active:focus,
.mockup.wireframe .btn-primary.disabled,
.mockup.wireframe .btn-primary.disabled:active,
.mockup.wireframe .btn-primary.disabled:focus,
.mockup.wireframe .btn-primary.disabled:hover,
.mockup.wireframe .btn-primary[disabled],
.mockup.wireframe .btn-primary[disabled]:active,
.mockup.wireframe .btn-primary[disabled]:focus,
.mockup.wireframe .btn-primary[disabled]:hover,
.mockup.wireframe .btn-secondary,
.mockup.wireframe .btn-secondary.active,
.mockup.wireframe .btn-secondary:active,
.mockup.wireframe .btn-secondary:focus,
.mockup.wireframe .btn-secondary:active:focus,
.mockup.wireframe .btn-secondary.disabled,
.mockup.wireframe .btn-secondary.disabled:active,
.mockup.wireframe .btn-secondary.disabled:focus,
.mockup.wireframe .btn-secondary.disabled:hover,
.mockup.wireframe .btn-secondary[disabled],
.mockup.wireframe .btn-secondary[disabled]:active,
.mockup.wireframe .btn-secondary[disabled]:focus,
.mockup.wireframe .btn-secondary[disabled]:hover,
.mockup.wireframe .btn-success,
.mockup.wireframe .btn-success.active,
.mockup.wireframe .btn-success:active,
.mockup.wireframe .btn-success:focus,
.mockup.wireframe .btn-success:active:focus,
.mockup.wireframe .btn-success.disabled,
.mockup.wireframe .btn-success.disabled:active,
.mockup.wireframe .btn-success.disabled:focus,
.mockup.wireframe .btn-success.disabled:hover,
.mockup.wireframe .btn-success[disabled],
.mockup.wireframe .btn-success[disabled]:active,
.mockup.wireframe .btn-success[disabled]:focus,
.mockup.wireframe .btn-success[disabled]:hover,
.mockup.wireframe .btn-danger,
.mockup.wireframe .btn-danger.active,
.mockup.wireframe .btn-danger:active,
.mockup.wireframe .btn-danger:focus,
.mockup.wireframe .btn-danger:active:focus,
.mockup.wireframe .btn-danger.disabled,
.mockup.wireframe .btn-danger.disabled:active,
.mockup.wireframe .btn-danger.disabled:focus,
.mockup.wireframe .btn-danger.disabled:hover,
.mockup.wireframe .btn-danger[disabled],
.mockup.wireframe .btn-danger[disabled]:active,
.mockup.wireframe .btn-danger[disabled]:focus,
.mockup.wireframe .btn-danger[disabled]:hover,
.mockup.wireframe .btn-info,
.mockup.wireframe .btn-info.active,
.mockup.wireframe .btn-info:active,
.mockup.wireframe .btn-info:focus,
.mockup.wireframe .btn-info:active:focus,
.mockup.wireframe .btn-info.disabled,
.mockup.wireframe .btn-info.disabled:active,
.mockup.wireframe .btn-info.disabled:focus,
.mockup.wireframe .btn-info.disabled:hover,
.mockup.wireframe .btn-info[disabled],
.mockup.wireframe .btn-info[disabled]:active,
.mockup.wireframe .btn-info[disabled]:focus,
.mockup.wireframe .btn-info[disabled]:hover {
    border-radius: 0;
    border-style: solid;
    border-width: 3px;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
    color: #000;
    font-family: "Comic Sans MS", Arial, sans-serif !important;
    text-shadow: none;
}

.mockup.wireframe .btn-default .fa,
.mockup.wireframe .btn-default.active .fa,
.mockup.wireframe .btn-default:active .fa,
.mockup.wireframe .btn-default:focus .fa,
.mockup.wireframe .btn-default:active:focus .fa,
.mockup.wireframe .btn-default.disabled .fa,
.mockup.wireframe .btn-default.disabled:active .fa,
.mockup.wireframe .btn-default.disabled:focus .fa,
.mockup.wireframe .btn-default.disabled:hover .fa,
.mockup.wireframe .btn-default[disabled] .fa,
.mockup.wireframe .btn-default[disabled]:active .fa,
.mockup.wireframe .btn-default[disabled]:focus .fa,
.mockup.wireframe .btn-default[disabled]:hover .fa,
.mockup.wireframe .btn-primary .fa,
.mockup.wireframe .btn-primary.active .fa,
.mockup.wireframe .btn-primary:active .fa,
.mockup.wireframe .btn-primary:focus .fa,
.mockup.wireframe .btn-primary:active:focus .fa,
.mockup.wireframe .btn-primary.disabled .fa,
.mockup.wireframe .btn-primary.disabled:active .fa,
.mockup.wireframe .btn-primary.disabled:focus .fa,
.mockup.wireframe .btn-primary.disabled:hover .fa,
.mockup.wireframe .btn-primary[disabled] .fa,
.mockup.wireframe .btn-primary[disabled]:active .fa,
.mockup.wireframe .btn-primary[disabled]:focus .fa,
.mockup.wireframe .btn-primary[disabled]:hover .fa,
.mockup.wireframe .btn-secondary .fa,
.mockup.wireframe .btn-secondary.active .fa,
.mockup.wireframe .btn-secondary:active .fa,
.mockup.wireframe .btn-secondary:focus .fa,
.mockup.wireframe .btn-secondary:active:focus .fa,
.mockup.wireframe .btn-secondary.disabled .fa,
.mockup.wireframe .btn-secondary.disabled:active .fa,
.mockup.wireframe .btn-secondary.disabled:focus .fa,
.mockup.wireframe .btn-secondary.disabled:hover .fa,
.mockup.wireframe .btn-secondary[disabled] .fa,
.mockup.wireframe .btn-secondary[disabled]:active .fa,
.mockup.wireframe .btn-secondary[disabled]:focus .fa,
.mockup.wireframe .btn-secondary[disabled]:hover .fa,
.mockup.wireframe .btn-success .fa,
.mockup.wireframe .btn-success.active .fa,
.mockup.wireframe .btn-success:active .fa,
.mockup.wireframe .btn-success:focus .fa,
.mockup.wireframe .btn-success:active:focus .fa,
.mockup.wireframe .btn-success.disabled .fa,
.mockup.wireframe .btn-success.disabled:active .fa,
.mockup.wireframe .btn-success.disabled:focus .fa,
.mockup.wireframe .btn-success.disabled:hover .fa,
.mockup.wireframe .btn-success[disabled] .fa,
.mockup.wireframe .btn-success[disabled]:active .fa,
.mockup.wireframe .btn-success[disabled]:focus .fa,
.mockup.wireframe .btn-success[disabled]:hover .fa,
.mockup.wireframe .btn-danger .fa,
.mockup.wireframe .btn-danger.active .fa,
.mockup.wireframe .btn-danger:active .fa,
.mockup.wireframe .btn-danger:focus .fa,
.mockup.wireframe .btn-danger:active:focus .fa,
.mockup.wireframe .btn-danger.disabled .fa,
.mockup.wireframe .btn-danger.disabled:active .fa,
.mockup.wireframe .btn-danger.disabled:focus .fa,
.mockup.wireframe .btn-danger.disabled:hover .fa,
.mockup.wireframe .btn-danger[disabled] .fa,
.mockup.wireframe .btn-danger[disabled]:active .fa,
.mockup.wireframe .btn-danger[disabled]:focus .fa,
.mockup.wireframe .btn-danger[disabled]:hover .fa,
.mockup.wireframe .btn-info .fa,
.mockup.wireframe .btn-info.active .fa,
.mockup.wireframe .btn-info:active .fa,
.mockup.wireframe .btn-info:focus .fa,
.mockup.wireframe .btn-info:active:focus .fa,
.mockup.wireframe .btn-info.disabled .fa,
.mockup.wireframe .btn-info.disabled:active .fa,
.mockup.wireframe .btn-info.disabled:focus .fa,
.mockup.wireframe .btn-info.disabled:hover .fa,
.mockup.wireframe .btn-info[disabled] .fa,
.mockup.wireframe .btn-info[disabled]:active .fa,
.mockup.wireframe .btn-info[disabled]:focus .fa,
.mockup.wireframe .btn-info[disabled]:hover .fa {
    color: #000;
    font-family: "Comic Sans MS", Arial, sans-serif !important;
}

/* Status Button */

.mockup.wireframe button.status {
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
    font-family: "Comic Sans MS", Arial, sans-serif !important;
    font-size: 8px;
}

/* Name Values */

.mockup.wireframe .name-values {
    border-style: solid;
    border-width: 3px;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
}

.mockup.wireframe .name-values .name {
    font-family: "Comic Sans MS", Arial, sans-serif !important;
}

/* Alert */

.mockup.wireframe .alert.alert-danger,
.mockup.wireframe .alert.alert-success,
.mockup.wireframe .alert.alert-warning {
    border-style: solid;
    border-width: 2px;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
}

/* Modal Action Dialog */

.mockup.wireframe .modal-dialog-action .modal-dialog {
    max-width: 506px;
}

.mockup.wireframe .modal-dialog-action .modal-header {
    border-style: solid;
    border-width: 0 0 2px 0;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
}

.mockup.wireframe .modal-dialog-action .modal-content {
    border-style: solid;
    border-width: 3px;
    border-image: url("mockup/images/border.png") 10 stretch stretch !important;
}

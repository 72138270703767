/* Blocks */

.binary-images-with-upload .list.blocks { margin-bottom: -25px; }
.binary-images-with-upload .list.blocks .block { margin-bottom: 15px; }

/* Structure */

.binary-images-with-upload { margin: 0 -20px -10px -20px; padding-left: 10px; padding-right: 10px; position: relative; }
.binary-images-with-upload:after { clear: both; content: ''; display: block; }
.form-group-text-center .binary-images-with-upload { display: inline-block; }

.binary-images-with-upload .image-container { display: flex; align-items: center; justify-content: center; text-align: center; }
.binary-images-with-upload .image-container { background: #333; float: left; margin: 0 10px 0 10px; overflow: hidden; position: relative; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; }
.binary-images-with-upload .image { position: absolute; z-index: 50; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; }
.binary-images-with-upload .image:before { content: ''; display: inline-block; height: 100%; vertical-align: middle; }
.binary-images-with-upload .image img { display: inline-block; text-align: center; vertical-align: middle; width: 100%; min-height: 50px; }
.binary-images-with-upload .uploading { background: rgba(0,0,0,.3); position: absolute; height: 100%; width: 100%; text-align: center; top: 0; z-index: 51; }
.binary-images-with-upload .uploading:before { content: ''; display: inline-block; height: 100%; vertical-align: middle; }
.binary-images-with-upload .uploading .fa { color: #fff; display: inline-block; font-size: 30px; padding: 0 0 0 1px; text-align: center; vertical-align: middle; width: 31px; height: 30px; }
.binary-images-with-upload .file-input-mask { background: #fafafa; display: inline-block; position: relative; overflow: hidden; text-align: center; height: 100%; width: 100%; }
.binary-images-with-upload .image-container .actions { border: 0; position: absolute; margin: 0 !important; padding: 0 !important; top: 10px; right: 10px; font-size: 10px; overflow: visible; text-align: center; text-transform: uppercase; z-index: 51; }
.binary-images-with-upload .image-container .actions .fa { background: #f0f0f0; color: #ccc; cursor: pointer; display: block; opacity: 1.0; height: 20px; width: 20px; line-height: 18px; text-decoration: none; visibility: hidden; -webkit-border-radius: 20px; -moz-border-radius: 20px; border-radius: 20px; }
.binary-images-with-upload .image-container:hover .actions .fa { color: #000; opacity: 1.0;  visibility: visible; }
.binary-images-with-upload .image-container:hover .actions .fa:hover { background: #c00; color: #fff; }
.binary-images-with-upload .image-container .actions a.remove.disabled { background: #e8e8e8; color: #e8e8e8; }
.binary-images-with-upload .blank { margin-left: 10px; }

/* Add */

.binary-images-with-upload .add { background: #f6f6f6; cursor: pointer; display: inline-block; margin: 0 10px 5px; position: relative; top: 0; z-index: 51;  -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; }
.binary-images-with-upload .add:before { content: ''; display: inline-block; height: 100%; vertical-align: middle; }
.binary-images-with-upload .add:after { color: #ccc; display: inline-block; content: "\f055"; font-family: FontAwesome; font-size: 30px; text-align: center; vertical-align: middle; width: 100%; }
.binary-images-with-upload .add:hover:after { color: #333; }

/* Fit */

.binary-images-with-upload.fit-width .image-container { width: calc(100% - 19.5px); }
.binary-images-with-upload.fit-width .image { width: 100%; }
.binary-images-with-upload.fit-width .image img { }

.binary-images-with-upload.fit-cover .image-container { width: calc(100% - 19.5px); }
.binary-images-with-upload.fit-cover .image { height: 100%; width: 100%; }
.binary-images-with-upload.fit-cover .image img { min-height: 100%; height: 100%; object-fit: cover; }

/* Sizes */

.binary-images-with-upload .block { width: calc(136px + 20px); }
.binary-images-with-upload .image-container { height: 136px; }
.binary-images-with-upload .add { height: 136px; width: 136px; }

.binary-images-with-upload.medium .block { width: calc(230px + 20px); }
.binary-images-with-upload.medium .image-container { height: 230px; }
.binary-images-with-upload.medium .add { height: 230px; width: 230px; }

/* Horizontal Columns */

.binary-images-with-upload.images-across-1 .block { width: 100%; }
.binary-images-with-upload.images-across-1 .image-container { height: 100%; }
.binary-images-with-upload.images-across-1 .image { height: 100%; position: unset; }
.binary-images-with-upload.images-across-1 .image img { object-fit: unset; width: 100%; }
.binary-images-with-upload.images-across-1 .add { height: 150px; width: calc(100% - 20px); }

.binary-images-with-upload.images-across-2 .block { width: 50%; }
.binary-images-with-upload.images-across-2 .image-container { height: 230px; }
.binary-images-with-upload.images-across-2 .add { height: 230px; width: calc(50% - 10px); }

.binary-images-with-upload.images-across-3 .block { width: 33.33333%; }
.binary-images-with-upload.images-across-3 .image-container { height: 230px; }
.binary-images-with-upload.images-across-3 .add { height: 230px; width: calc(33% - 10px); }

.binary-images-with-upload.images-across-4 .block { width: 25%; }
.binary-images-with-upload.images-across-4 .image-container { height: 180px; }
.binary-images-with-upload.images-across-4 .add { height: 180px; width: calc(25% - 10px); }

.binary-images-with-upload.images-across-5 .block { width: 20%; }
.binary-images-with-upload.images-across-5 .image-container { height: 180px; }
.binary-images-with-upload.images-across-5 .add { height: 180px; width: calc(20% - 10px); }

.binary-images-with-upload.images-across-6 .block { width: 16.6666%; }
.binary-images-with-upload.images-across-6 .image-container { height: 180px; }
.binary-images-with-upload.images-across-6 .add { height: 180px; width: calc(16.6666% - 10px); }

.binary-images-with-upload.images-across-7 .block { width: 14.28%; }
.binary-images-with-upload.images-across-7 .image-container { height: 150px; }
.binary-images-with-upload.images-across-7 .add { height: 180px; width: calc(14.28% - 10px); }

.binary-images-with-upload.images-across-8 .block { width: 12.5%; }
.binary-images-with-upload.images-across-8 .image-container { height: 180px; }
.binary-images-with-upload.images-across-8 .add { height: 180px; width: calc(12.5% - 10px); }

@media screen and (max-width: 992px) {

    .binary-images-with-upload.images-across-5 .block { width: 25%; }
    .binary-images-with-upload.images-across-5 .image-container { height: 180px; }
    .binary-images-with-upload.images-across-5 .add { height: 180px; width: calc(25% - 10px); }

    .binary-images-with-upload.images-across-6 .block { width: 25%; }
    .binary-images-with-upload.images-across-6 .image-container { height: 180px; }
    .binary-images-with-upload.images-across-6 .add { height: 180px; width: calc(25% - 10px); }

    .binary-images-with-upload.images-across-7 .block { width: 25%; }
    .binary-images-with-upload.images-across-7 .image-container { height: 180px; }
    .binary-images-with-upload.images-across-7 .add { height: 180px; width: calc(25% - 10px); }

    .binary-images-with-upload.images-across-8 .block { width: 25%; }
    .binary-images-with-upload.images-across-8 .image-container { height: 180px; }
    .binary-images-with-upload.images-across-8 .add { height: 180px; width: calc(25% - 10px); }

}

@media screen and (max-width: 768px) {

    .binary-images-with-upload.images-across-2 .block { width: 100%; }
    .binary-images-with-upload.images-across-2 .image-container { height: 100%; }
    .binary-images-with-upload.images-across-2 .image { height: 100%; position: unset; }
    .binary-images-with-upload.images-across-2 .image img { object-fit: unset; width: 100%; }
    .binary-images-with-upload.images-across-2 .add { height: 150px; width: calc(100% - 20px); }

    .binary-images-with-upload.images-across-3 .block { width: 100%; }
    .binary-images-with-upload.images-across-3 .image-container { height: 100%; }
    .binary-images-with-upload.images-across-3 .image { height: 100%; position: unset; }
    .binary-images-with-upload.images-across-3 .image img { object-fit: unset; width: 100%; }
    .binary-images-with-upload.images-across-3 .add { height: 150px; width: calc(100% - 20px); }

    .binary-images-with-upload.images-across-4 .block { width: 100%; }
    .binary-images-with-upload.images-across-4 .image-container { height: 100%; }
    .binary-images-with-upload.images-across-4 .image { height: 100%; position: unset; }
    .binary-images-with-upload.images-across-4 .image img { object-fit: unset; width: 100%; }
    .binary-images-with-upload.images-across-4 .add { height: 150px; width: calc(100% - 20px); }

    .binary-images-with-upload.images-across-5 .block { width: 100%; }
    .binary-images-with-upload.images-across-5 .image-container { height: 100%; }
    .binary-images-with-upload.images-across-5 .image { height: 100%; position: unset; }
    .binary-images-with-upload.images-across-5 .image img { object-fit: unset; width: 100%; }
    .binary-images-with-upload.images-across-5 .add { height: 150px; width: calc(100% - 20px); }

    .binary-images-with-upload.images-across-6 .block { width: 100%; }
    .binary-images-with-upload.images-across-6 .image-container { height: 100%; }
    .binary-images-with-upload.images-across-6 .image { height: 100%; position: unset; }
    .binary-images-with-upload.images-across-6 .image img { object-fit: unset; width: 100%; }
    .binary-images-with-upload.images-across-6 .add { height: 150px; width: calc(100% - 20px); }

    .binary-images-with-upload.images-across-7 .block { width: 100%; }
    .binary-images-with-upload.images-across-7 .image-container { height: 100%; }
    .binary-images-with-upload.images-across-7 .image { height: 100%; position: unset; }
    .binary-images-with-upload.images-across-7 .image img { object-fit: unset; width: 100%; }
    .binary-images-with-upload.images-across-7 .add { height: 150px; width: calc(100% - 20px); }

    .binary-images-with-upload.images-across-8 .block { width: 100%; }
    .binary-images-with-upload.images-across-8 .image-container { height: 100%; }
    .binary-images-with-upload.images-across-8 .image { height: 100%; position: unset; }
    .binary-images-with-upload.images-across-8 .image img { object-fit: unset; width: 100%; }
    .binary-images-with-upload.images-across-8 .add { height: 150px; width: calc(100% - 20px); }

}

/* Avatar */

.binary-images-with-upload.avatar .image-container { background: transparent; }
.binary-images-with-upload.avatar .image { background: transparent; height: 100%; -webkit-border-radius: 200px; -moz-border-radius: 200px; border-radius: 200px; }
.binary-images-with-upload.avatar .image img { width: 100%; height: 100%; object-fit: cover; -webkit-border-radius: 200px; -moz-border-radius: 200px; border-radius: 200px; }  /* object-fit ie edge support is limited - might not work, try commented out alternative below */
.binary-images-with-upload.avatar .image .uploading { -webkit-border-radius: 200px; -moz-border-radius: 200px; border-radius: 200px; }
.binary-images-with-upload.avatar .add { -webkit-border-radius: 200px; -moz-border-radius: 200px; border-radius: 200px; }

/* Frame */

body.mockup {
    border: 1px solid #ccc;
    max-width: 1250px;
    margin: 0 auto;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -moz-box-shadow: 0 0 15px #dde8f4;
    -webkit-box-shadow: 0 0 15px #dde8f4;
    box-shadow: 0 0 15px #dde8f4;
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

body.mockup:before {
    background: #e8e8e8;
    color: #999;
    content: "\25EF\00a0\00a0\25EF\00a0\00a0\25EF";
    display: block;
    width: 100%;
    min-height: 40px;
    padding: 10px 20px;
    -moz-border-radius: 8px 8px 0 0;
    -webkit-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
}

@media (max-width: 768px) {
    body.mockup:before {
        content: "";
    }
}

/* Title */

body.mockup .mockup-title {
    position: relative;
    top: -34px;
    text-align: center;
    height: 0;
}

/* Common */

body.mockup {
}


.name-values .pull-right .fa { margin-left: 20px; }
.name-values { border-bottom: 1px solid #e6eaee; margin-bottom: 20px; }
.name-values .name-value { border-left: 1px solid #e6eaee; border-right: 1px solid #e6eaee; border-bottom: 0; margin: 0 0 0 0; }
.name-values .name-value > * { border-top: 1px solid #e6eaee; padding: 10px 20px; }
.name-values .name-value:not(:first-child) > * { border-top-color: #f5f8fa; }
.name-values .name-value > .name { background: #f5f8fa; color: #56618F; font-size: 12px; font-weight: 400; padding-top: 12px; text-transform: uppercase; }
.name-values .name-value > .value { background: #fff; color: #44556f; }
.name-values .name-value > .value.text-muted { color: #7F8FA4; }
.name-values .name-value > .value .dropdown-toggle.btn-xs { margin-top: -5px; }
.name-values .name-value > .value .form-group { margin-bottom: 0; }

.name-values .dropdown-menu { font-size: 12px; left: 0; right: auto; padding: 8px 0; text-align: left; transform: none; }

@media screen and (max-width: 992px) {
    .name-values .row-eq-height { display: block; }
    .name-values { border-bottom: 1px solid #e6eaee; }
    .name-values .name-value {  margin: 0 0 0 0; }
    .name-values .name-value > * { border: 0; padding: 15px 20px; margin: 0; min-height: 51px; }
    .name-values .name-value > .name { border-top: 1px solid #e6eaee; }
}

@media (min-width: 992px) {
    .panel-body .name-values .name-value > .name { width: 30%; }
    .panel-body .name-values .name-value > .value { width: 70%; }
}

@media screen and (max-width: 1200px) {
    .name-values .form-group { margin: 0 0 15px 0; }
    .name-values .actions { margin: 0; }
    .name-values .form-inline .actions button, .name-values .form-inline .actions a.btn { display: block; margin-bottom: 15px; }
    .name-values .form-inline .actions button:last-child, .name-values .form-inline .actions a.btn:last-child { margin-bottom: 0; }
    .name-values .form-inline .actions button+button, .name-values .form-inline .actions a.btn+a.btn { margin-left: 0; }
}

.theme-layout-default .layout--content-3 > div {
    padding: 80px 0;
}

.theme-layout-default .layout--content-3 .primary .ce-block__content > * {
    text-align: center !important;
}

/* Header */

.theme-layout-default .layout--content-3 .primary .ce-block:nth-child(1) .ce-block__content > .ce-header,
.theme-layout-default .layout--content-3 .primary .ce-block:nth-child(2) .ce-block__content > .ce-header {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.theme-layout-default .layout--content-3 .primary .ce-block:nth-child(2) .ce-block__content > .ce-header {
    margin-bottom: 50px;
}

/* Description */

.theme-layout-default .layout--content-3 .primary .ce-block:nth-child(1) .ce-block__content > .ce-paragraph,
.theme-layout-default .layout--content-3 .primary .ce-block:nth-child(2) .ce-block__content > .ce-paragraph {
    color: #919dab;
    font-family: "Poppins", arial, sans-serif;
    font-size: 18px;
    line-height: 28px;
    margin: 30px 50px 50px;
}

.theme-layout-default .layout--content-3 .primary .ce-block:nth-child(1) .ce-block__content > .ce-paragraph {
    margin-top: 0;
    margin-bottom: 10px;
}

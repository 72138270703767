/* End Point */

.api-end-point-documentation .end-point {
    margin-bottom: 20px;
}

.api-end-point-documentation .end-point h4 {
    margin-top: 0;
}

.api-end-point-documentation .end-point .panel-body > *:last-child,
.api-end-point-documentation .end-point .panel-body > *:last-child > *:last-child,
.api-end-point-documentation .end-point .panel-body > *:last-child > *:last-child > *:last-child {
    margin-bottom: 0 !important;
}

/* Method, Path, Headings */

.api-end-point-documentation .end-point h5 {
}

.api-end-point-documentation .end-point .method-and-path {
}

/* Field */

.api-end-point-documentation .end-point .field:hover td {
    background: inherit !important;
}

.api-end-point-documentation .end-point .field-0 {
    background: #ffffff;
}

.api-end-point-documentation .end-point .field-1 {
    background: #fafafa;
}

.api-end-point-documentation .end-point .field-2 {
    background: #f2f2f2;
}

.api-end-point-documentation .end-point .field-3 {
    background: #e9e9e9;
}

.api-end-point-documentation .end-point .field-4 {
    background: #e1e1e1;
}

.api-end-point-documentation .end-point .field-5 {
    background: #d8d8d8;
}

.api-end-point-documentation .end-point .field-6 {
    background: #d0d0d0;
}

.api-end-point-documentation .end-point .field-7 {
    background: #c7c7c7;
}

.api-end-point-documentation .end-point .field-8 {
    background: #bfbfbf;
}

.api-end-point-documentation .end-point .field-9 {
    background: #b6b6b6;
}

.api-end-point-documentation .end-point .field td {
}

.api-end-point-documentation .end-point .field .padding {
    padding-right: 20px;
}

/* Type */

.api-end-point-documentation .end-point .type {
    color: #7F8FA4;
}

/* Description */

.api-end-point-documentation .end-point .description ul {
    margin: 2px 0 0 0 !important;
}

.api-end-point-documentation .end-point .description li {
    margin: 0 0 0 20px !important;
}

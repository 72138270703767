@keyframes flickerAnimation { 0% { opacity:1; } 50% { opacity:0; } 100% { opacity:1; } }
@-o-keyframes flickerAnimation { 0% { opacity:1; } 50% { opacity:0; } 100% { opacity:1; } }
@-moz-keyframes flickerAnimation { 0% { opacity:1; } 50% { opacity:0; } 100% { opacity:1; } }
@-webkit-keyframes flickerAnimation { 0% { opacity:1; } 50% { opacity:0; } 100% { opacity:1; } }
.animate-flicker { -webkit-animation: flickerAnimation 1s infinite; -moz-animation: flickerAnimation 1s infinite; -o-animation: flickerAnimation 1s infinite; animation: flickerAnimation 1s infinite; }

@keyframes fadeInAnimation { 0% { opacity:0; } 100% { opacity:1; } }
@-o-keyframes fadeInAnimation { 0% { opacity:0; } 100% { opacity:1; } }
@-moz-keyframes fadeInAnimation { 0% { opacity:0; } 100% { opacity:1; } }
@-webkit-keyframes fadeInAnimation { 0% { opacity:0; } 100% { opacity:1; } }
.animate-fadeIn { -webkit-animation: fadeInAnimation 3s; -moz-animation: fadeInAnimation 3s; -o-animation: fadeInAnimation 3s; animation: fadeInAnimation 3s; }

@keyframes progressAnimation { 0% { width:0; } 100% { width:90%; } }
@-o-keyframes progressAnimation { 0% { width:0; } 100% { width:90%; } }
@-moz-keyframes progressAnimation { 0% { width:0; } 100% { width:90%; } }
@-webkit-keyframes progressAnimation { 0% { width:0; } 100% { width:90%; } }
.animate-progress { -webkit-animation: progressAnimation 3s; -moz-animation: progressAnimation 3s; -o-animation: progressAnimation 3s; animation: progressAnimation 3s; }

@keyframes spinAnimation { 0% { transform:rotate(0deg); } 100% { transform:rotate(360deg); } }
@-o-keyframes spinAnimation { 0% { -o-transform:rotate(0deg); } 100% { -o-transform:rotate(360deg); } }
@-moz-keyframes spinAnimation { 0% { -moz-transform:rotate(0deg); } 100% { -moz-transform:rotate(360deg); } }
@-webkit-keyframes spinAnimation { 0% { -webkit-transform:rotate(0deg); } 100% { -webkit-transform:rotate(360deg); } }
.animate-spin { -webkit-animation: spinAnimation 2s infinite; -moz-animation: spinAnimation 2s infinite; -o-animation: spinAnimation 2s infinite; animation: spinAnimation 2s infinite; }

div.input-rich-text { font-family: arial, helvetica, sans-serif; font-size: 14px; }
div.input-rich-text > div { border: 1px solid #E6EAEE; color: #1A91EB; font-family: "Raleway", arial, helvetica, sans-serif; font-size: 14px; }
div.input-rich-text .input-rich-text-editor { font-family: arial, helvetica, sans-serif; font-size: 14px; }

/* Toolbar */

div.input-rich-text .input-rich-text-toolbar { border-bottom: 1px solid #E6EAEE; }
div.input-rich-text .input-rich-text-toolbar button { background: #fff; border: 1px solid #E6EAEE; }
div.input-rich-text .input-rich-text-toolbar select { line-height: 1.4em !important; }
div.input-rich-text .input-rich-text-toolbar > div > span > span { border: 0; }
div.input-rich-text .input-rich-text-toolbar > div > div:not(:first-child) { margin-left: -1px; }

/* Popover (link) */

div.input-rich-text .input-rich-text-toolbar > div > div > div { background: #fff; border-color: #E6EAEE; color: #434A54; }
div.input-rich-text .input-rich-text-toolbar > div > div > div { padding: 10px; margin-left: -130px; }
div.input-rich-text .input-rich-text-toolbar > div > div > div:before { border-bottom-color: #DFE6F4; margin-left: 130px; }
div.input-rich-text .input-rich-text-toolbar > div > div > div:after { margin-left: 130px; }

div.input-rich-text .input-rich-text-toolbar > div > div > div > div > input[type="text"] { background: #fff; border-color: #E6EAEE; color: #434A54; }
div.input-rich-text .input-rich-text-toolbar > div > div > div > div > input[type="text"]:focus { border: 1px solid #1A91EB; outline: none; border-radius: 2px; }

div.input-rich-text .input-rich-text-toolbar > div > div > div > div > div > div:first-child { margin-right: -1px; }

div.input-rich-text .input-rich-text-toolbar > div > div > div > div:first-child { margin: 0 0 10px 0; }
div.input-rich-text .input-rich-text-toolbar > div > div > div > div:not(:first-child) { margin: 5px 0 0 0; }
div.input-rich-text .input-rich-text-toolbar > div > div > div > div:not(:first-child) label { margin: 0; }
div.input-rich-text .input-rich-text-toolbar > div > div > div > div:not(:first-child) input { margin-top: 3px; margin-right: 8px; vertical-align: top; }
div.input-rich-text .input-rich-text-toolbar > div > div > div > div:not(:first-child) span { font-weight: normal; margin-top: -2px; }

/* Content */

div.input-rich-text .public-DraftEditor-content > div > blockquote { border-left: 5px solid #eee; color: #000; font-family: "Raleway", arial, helvetica, sans-serif; font-size: 16px; font-style: italic; line-height: 1.6em; letter-spacing: 0; padding: 10px 20px; margin: 0 0 20px; }
div.input-rich-text .public-DraftEditor-content ol:not(.nav):not(.list-group):not(.pagination):not(.dropdown-menu):not(.custom) > li { list-style-type: none; }
div.input-rich-text .public-DraftEditor-content ul:not(.nav):not(.list-group):not(.pagination):not(.dropdown-menu):not(.custom) > li { list-style-type: disc; }

/* Formatted Text when disabled */

div.input-rich-text-disabled { background: #f6f6f6; border: 1px solid #E6EAEE; padding: 12px; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; }
div.input-rich-text-disabled > div > .formatted-text > :first-child { margin-top: 0; padding-top: 0; }
div.input-rich-text-disabled > div > .formatted-text > :last-child:not(.any) { margin-bottom: 0; padding-bottom: 0; }
div.input-rich-text-disabled > div > .formatted-text > ul:last-child > li:last-child { margin-bottom: 0; padding-bottom: 0; }
div.input-rich-text-disabled > div > .formatted-text > ol:last-child > li:last-child { margin-bottom: 0; padding-bottom: 0; }

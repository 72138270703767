.top0 { top: 0; }
.top1 { top: 1px; }
.top2 { top: 2px !important; }
.top5 { top: 5px !important; }
.top10 { top: 10px !important; }
.top15 { top: 15px !important; }
.top20 { top: 20px !important; }
.top30 { top: 30px !important; }
.top40 { top: 40px !important; }
.top50 { top: 50px !important; }
.top60 { top: 60px !important; }
.top70 { top: 70px !important; }
.top80 { top: 80px !important; }
.top90 { top: 90px !important; }
.top100 { top: 100px !important; }

.margin0 { margin: 0 !important; }
.margin5 { margin: 5px !important; }
.margin10 { margin: 10px !important; }
.margin15 { margin: 15px !important; }
.margin20 { margin: 20px !important; }
.margin30 { margin: 30px !important; }
.margin40 { margin: 40px !important; }
.margin50 { margin: 50px !important; }
.margin60 { margin: 60px !important; }
.margin70 { margin: 70px !important; }
.margin80 { margin: 80px !important; }
.margin90 { margin: 90px !important; }
.margin100 { margin: 100px !important; }

.marginTop0 { margin-top: 0 !important; }
.marginTop5 { margin-top: 5px !important; }
.marginTop10 { margin-top: 10px !important; }
.marginTop15 { margin-top: 15px !important; }
.marginTop20 { margin-top: 20px !important; }
.marginTop30 { margin-top: 30px !important; }
.marginTop40 { margin-top: 40px !important; }
.marginTop50 { margin-top: 50px !important; }
.marginTop60 { margin-top: 60px !important; }
.marginTop70 { margin-top: 70px !important; }
.marginTop80 { margin-top: 80px !important; }
.marginTop90 { margin-top: 90px !important; }
.marginTop100 { margin-top: 100px !important; }

.marginBottom0 { margin-bottom: 0 !important; }
.marginBottom5 { margin-bottom: 5px !important; }
.marginBottom10 { margin-bottom: 10px !important; }
.marginBottom15 { margin-bottom: 15px !important; }
.marginBottom20 { margin-bottom: 20px !important; }
.marginBottom30 { margin-bottom: 30px !important; }
.marginBottom40 { margin-bottom: 40px !important; }
.marginBottom50 { margin-bottom: 50px !important; }
.marginBottom60 { margin-bottom: 60px !important; }
.marginBottom70 { margin-bottom: 70px !important; }
.marginBottom80 { margin-bottom: 80px !important; }
.marginBottom90 { margin-bottom: 90px !important; }
.marginBottom100 { margin-bottom: 100px !important; }

.marginLeft0 { margin-left: 0 !important; }
.marginLeft5 { margin-left: 5px !important; }
.marginLeft10 { margin-left: 10px !important; }
.marginLeft15 { margin-left: 15px !important; }
.marginLeft20 { margin-left: 20px !important; }
.marginLeft30 { margin-left: 30px !important; }
.marginLeft40 { margin-left: 40px !important; }
.marginLeft50 { margin-left: 50px !important; }
.marginLeft60 { margin-left: 60px !important; }
.marginLeft70 { margin-left: 70px !important; }
.marginLeft80 { margin-left: 80px !important; }
.marginLeft90 { margin-left: 90px !important; }
.marginLeft100 { margin-left: 100px !important; }

.marginRight0 { margin-right: 0 !important; }
.marginRight5 { margin-right: 5px !important; }
.marginRight10 { margin-right: 10px !important; }
.marginRight15 { margin-right: 15px !important; }
.marginRight20 { margin-right: 20px !important; }
.marginRight30 { margin-right: 30px !important; }
.marginRight40 { margin-right: 40px !important; }
.marginRight50 { margin-right: 50px !important; }
.marginRight60 { margin-right: 60px !important; }
.marginRight70 { margin-right: 70px !important; }
.marginRight80 { margin-right: 80px !important; }
.marginRight90 { margin-right: 90px !important; }
.marginRight100 { margin-right: 100px !important; }

.padding0 { padding: 0 !important; }
.padding5 { padding: 5px !important; }
.padding10 { padding: 10px !important; }
.padding15 { padding: 15px !important; }
.padding20 { padding: 20px !important; }
.padding30 { padding: 30px !important; }
.padding40 { padding: 40px !important; }
.padding50 { padding: 50px !important; }
.padding60 { padding: 60px !important; }
.padding70 { padding: 70px !important; }
.padding80 { padding: 80px !important; }
.padding90 { padding: 90px !important; }
.padding100 { padding: 100px !important; }

.paddingTop0 { padding-top: 0 !important; }
.paddingTop5 { padding-top: 5px !important; }
.paddingTop10 { padding-top: 10px !important; }
.paddingTop15 { padding-top: 15px !important; }
.paddingTop20 { padding-top: 20px !important; }
.paddingTop30 { padding-top: 30px !important; }
.paddingTop40 { padding-top: 40px !important; }
.paddingTop50 { padding-top: 50px !important; }
.paddingTop60 { padding-top: 60px !important; }
.paddingTop70 { padding-top: 70px !important; }
.paddingTop80 { padding-top: 80px !important; }
.paddingTop90 { padding-top: 90px !important; }
.paddingTop100 { padding-top: 100px !important; }

.paddingBottom0 { padding-bottom: 0 !important; }
.paddingBottom5 { padding-bottom: 5px !important; }
.paddingBottom10 { padding-bottom: 10px !important; }
.paddingBottom15 { padding-bottom: 15px !important; }
.paddingBottom20 { padding-bottom: 20px !important; }
.paddingBottom30 { padding-bottom: 30px !important; }
.paddingBottom40 { padding-bottom: 40px !important; }
.paddingBottom50 { padding-bottom: 50px !important; }
.paddingBottom60 { padding-bottom: 60px !important; }
.paddingBottom70 { padding-bottom: 70px !important; }
.paddingBottom80 { padding-bottom: 80px !important; }
.paddingBottom90 { padding-bottom: 90px !important; }
.paddingBottom100 { padding-bottom: 100px !important; }

.paddingLeft0 { padding-left: 0 !important; }
.paddingLeft5 { padding-left: 5px !important; }
.paddingLeft10 { padding-left: 10px !important; }
.paddingLeft15 { padding-left: 15px !important; }
.paddingLeft20 { padding-left: 20px !important; }
.paddingLeft30 { padding-left: 30px !important; }
.paddingLeft40 { padding-left: 40px !important; }
.paddingLeft50 { padding-left: 50px !important; }
.paddingLeft60 { padding-left: 60px !important; }
.paddingLeft70 { padding-left: 70px !important; }
.paddingLeft80 { padding-left: 80px !important; }
.paddingLeft90 { padding-left: 90px !important; }
.paddingLeft100 { padding-left: 100px !important; }

.paddingRight0 { padding-right: 0 !important; }
.paddingRight5 { padding-right: 5px !important; }
.paddingRight10 { padding-right: 10px !important; }
.paddingRight15 { padding-right: 15px !important; }
.paddingRight20 { padding-right: 20px !important; }
.paddingRight30 { padding-right: 30px !important; }
.paddingRight40 { padding-right: 40px !important; }
.paddingRight50 { padding-right: 50px !important; }
.paddingRight60 { padding-right: 60px !important; }
.paddingRight70 { padding-right: 70px !important; }
.paddingRight80 { padding-right: 80px !important; }
.paddingRight90 { padding-right: 90px !important; }
.paddingRight100 { padding-right: 100px !important; }

.maxWidth100 { max-width: 100px !important; }
.maxWidth120 { max-width: 120px !important; }
.maxWidth140 { max-width: 140px !important; }
.maxWidth150 { max-width: 150px !important; }
.maxWidth160 { max-width: 160px !important; }
.maxWidth180 { max-width: 180px !important; }
.maxWidth200 { max-width: 200px !important; }
.maxWidth220 { max-width: 220px !important; }
.maxWidth240 { max-width: 240px !important; }
.maxWidth250 { max-width: 250px !important; }
.maxWidth260 { max-width: 260px !important; }
.maxWidth280 { max-width: 280px !important; }
.maxWidth300 { max-width: 300px !important; }
.maxWidth320 { max-width: 320px !important; }
.maxWidth340 { max-width: 340px !important; }
.maxWidth350 { max-width: 350px !important; }
.maxWidth360 { max-width: 360px !important; }
.maxWidth380 { max-width: 380px !important; }
.maxWidth400 { max-width: 400px !important; }
.maxWidth420 { max-width: 420px !important; }
.maxWidth440 { max-width: 440px !important; }
.maxWidth450 { max-width: 450px !important; }
.maxWidth460 { max-width: 460px !important; }
.maxWidth480 { max-width: 480px !important; }
.maxWidth500 { max-width: 500px !important; }

#footer-container { border-top: 1px solid #E6EAEE; padding: 70px 0 70px 0; }
#footer .title { color: #1A91EB; font-size: 20px; font-weight: 500; margin: 0 0 10px 0; }
#footer .tagline { color: #1A91EB; margin: 0 0 30px 0; }
#footer-links { border-top: 1px solid #E6EAEE; margin-top: 80px; padding: 60px 0 0 0; }
#footer-nav { float: left; position: relative; left: 50%; }
#footer-nav ul { float: left; position: relative; margin: 0; padding: 0; overflow: hidden; left: -50%; }
#footer-nav li { float: left; list-style: none; margin: 0; padding: 0 20px; }
#footer-links a { color: #7F8FA4; }
#footer-links a:hover { color: #1A91EB; }

@media screen and (max-width: 768px) {
    #footer-container { }
    #footer-nav { float: none; position: relative; left: 0; }
    #footer-nav ul { float: none; position: relative; left: 0; margin: 0; padding: 0; overflow: hidden; }
    #footer-nav li { float: none; list-style: none; padding: 0; margin: 0 0 20px 0; text-align: center; }
}

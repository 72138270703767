.theme-layout-default .layout--showcase-7 {
    padding: 80px 0;
}

.theme-layout-default .layout--showcase-7 .primary .ce-block__content > * {
    text-align: center !important;
}

/* Header */

.theme-layout-default .layout--showcase-7 .primary .ce-block:nth-child(1) .ce-block__content > .ce-header,
.theme-layout-default .layout--showcase-7 .primary .ce-block:nth-child(2) .ce-block__content > .ce-header {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.theme-layout-default .layout--showcase-7 .primary .ce-block:nth-child(2) .ce-block__content > .ce-header {
    margin-bottom: 50px;
}

/* Description */

.theme-layout-default .layout--showcase-7 .primary .ce-block:nth-child(1) .ce-block__content > .ce-paragraph,
.theme-layout-default .layout--showcase-7 .primary .ce-block:nth-child(2) .ce-block__content > .ce-paragraph {
    color: #919dab;
    font-family: "Poppins", arial, sans-serif;
    font-size: 18px;
    line-height: 28px;
    margin: 30px 50px 50px;
}

.theme-layout-default .layout--showcase-7 .primary .ce-block:nth-child(1) .ce-block__content > .ce-paragraph {
    margin-top: 0;
    margin-bottom: 10px;
}

/* Features */

.theme-layout-default .layout--showcase-7 .form-group-features .features {
    margin-bottom: 0 !important;
}

.theme-layout-default .layout--showcase-7 .form-group-feature {
    text-align: center;
}

.theme-layout-default .layout--showcase-7 .form-group-feature:before {
    content: '';
    border: 2px solid #ebeaed !important;
    position: absolute;
    top: 0;
    left: 15px !important;
    width: calc(100% - 30px) !important;
    height: 100%;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
}

.theme-layout-default .layout--showcase-7.editing:hover .form-group-feature:before {
    border-color: transparent;
}

.theme-layout-default .layout--showcase-7.has-background .form-group-feature:before {
    border-color: rgba(0, 0, 0, 0.2) !important;
    background: #333;
}

.theme-layout-default .layout--showcase-7 .form-group-feature .feature .codex-editor {
    padding: 40px 50px 0 50px;
}

@media screen and (max-width: 768px) {

    .theme-layout-default .layout--showcase-7 .form-group-feature .feature .codex-editor {
        padding: 40px 10px 0 10px;
    }

}

/* Features - Image */

.theme-layout-default .layout--showcase-7 .feature .ce-block:last-child .images-tool {
    margin-bottom: -40px !important;
}

.theme-layout-default .layout--showcase-7 .feature .ce-block:last-child .images-tool:before {
    color: #ccc;
    content: '\2B24\2B24\2B24';
    font-size: 5px;
    line-height: 20px;
    letter-spacing: 5px;
    padding: 0 10px;
    height: 25px;
    text-align: left;
    top: -20px;
    left: 0;
    width: 100%;
    position: absolute;
    background: #f0f0f0;
    -moz-border-radius: 4px 4px 0 0 !important;
    -webkit-border-radius: 4px 4px 0 0 !important;
    border-radius: 4px 4px 0 0 !important;
}

.theme-layout-default .layout--showcase-7 .feature .ce-block:last-child .images-tool .form-group-binary-images-with-upload {
    padding-top: 5px;
    margin-bottom: 20px;
}

.theme-layout-default .layout--showcase-7 .feature .binary-images-with-upload .image-container,
.theme-layout-default .layout--showcase-7 .feature .binary-images-with-upload .add {
    height: 280px;
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

.theme-layout-default .layout--showcase-7 .feature .binary-images-with-upload .image-container img {
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

.theme-layout-default .layout--showcase-7 .feature .ce-images .binary-images-with-upload {
    min-height: 285px;
}

.theme-layout-default .layout--showcase-7 .feature .binary-images-with-upload .image-container img {
    object-fit: cover;
}

@media screen and (max-width: 768px) {

    .theme-layout-default .layout--showcase-7 .feature .binary-images-with-upload .image-container,
    .theme-layout-default .layout--showcase-7 .feature .binary-images-with-upload .add {
        height: 200px;
    }

    .theme-layout-default .layout--showcase-7 .feature .ce-images .binary-images-with-upload {
        min-height: 205px;
    }

}

/* Features - Header */

.theme-layout-default .layout--showcase-7 .feature .ce-block:nth-child(1) .ce-block__content {
    font-family: "Poppins", arial, sans-serif;
}

/* Features - Description */

.theme-layout-default .layout--showcase-7 .feature .ce-block:nth-child(2) .ce-block__content {
    color: #919dab !important;
    font-family: "Poppins", arial, sans-serif;
    font-size: 15px;
    margin-bottom: 50px;
}

/* Container, Row, Col */

.container.main { margin-top: 10px; margin-bottom: 150px; }
@media screen and (max-width: 768px) {
    .container.main { margin-top: 0; margin-bottom: 0; }
}

.container .full-width { margin-left: calc(-1 * (100vw - 100%) / 2); margin-right: calc(-1 * (100vw - 100%) / 2); }
.container .full-width > .container { padding: 0 15px; }
.container .container { padding: 0; }

.col-md-spacer { width: 10px; }

.gutter-none > [class*='col-'] { padding-right:0; padding-left:0; }

.row.display-flex { display: flex; flex-wrap: wrap; }
.row.display-flex > [class*='col-'] { display: flex; flex-direction: column; }

.row.margin-none > div > * { margin-bottom: 0; }

div > .row.fields:last-child > div:last-child { margin-bottom: 0; }

@media (min-width: 992px) {
    div > .row.fields:last-child > div[class*='col-md-'] { margin-bottom: 0; }
    div > .row.fields:last-child > div[class*='col-md-'] > .form-group { margin-bottom: 15px; }
}

.row-eq-height { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; }

@media screen and (min-width: 992px) {
    .row-eq-height-md { display: -webkit-box; display: -ms-flexbox; display: flex; }
}

@media screen and (min-width: 1200px) {
    .row-eq-height-lg { display: -webkit-box; display: -ms-flexbox; display: flex; }
}

.theme-layout-default .layout--content-26 > div {
    padding: 80px 0;
}

.theme-layout-default .layout--content-26 .primary .ce-block__content > * {
    text-align: center !important;
}

.theme-layout-default .layout--content-26 .primary .ce-block:first-child .ce-block__content > * {
    margin-top: 0;
    margin-bottom: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

/* Description */

.theme-layout-default .layout--content-26 .primary .ce-block:nth-child(1) .ce-block__content > .ce-paragraph,
.theme-layout-default .layout--content-26 .primary .ce-block:nth-child(2) .ce-block__content > .ce-paragraph {
    color: #919dab;
    font-family: "Poppins", arial, sans-serif;
    font-size: 18px;
    line-height: 28px;
    margin: 30px 50px 50px;
}

/* Features */

.theme-layout-default .layout--content-26 .row-features {
    margin-top: 30px !important;
}


#side > div { border-right: 1px solid #E6EAEE; margin: 15px 0 30px; }
#side > div > div.bottom { height: 33px; }
#side .nav .padded { margin: 3px 15px 0 0; }
#side .nav li { font-size: 15px; }
#side .nav li a { color: #7F8FA4; padding: 10px 30px 10px 0; }
#side .nav li.active a, #side .nav li.active a:hover, #side .nav li a.active, #side .nav li a.active:hover { background: transparent; color: #1A91EB; }
#side .nav li a:hover { background: transparent; color: #1A91EB; -webkit-transition: background-color 250ms linear; -moz-transition: background-color 250ms linear; -o-transition: background-color 250ms linear; -ms-transition: background-color 250ms linear; transition: background-color 250ms linear; }
#side .nav li.spacer a { cursor: default; }
#side .nav li.spacer a:hover { background: transparent; }
#side select { width: 145px; }
#side input { color: #000; }
#side button { width: 145px; }

@media (max-width: 992px) {
    #side  > div { border-right: 0; }
    #side .nav { border-right: 0; }
    #side .nav .padded { margin: 0; }
    #side .nav li { background: transparent; font-size: 15px; text-align: left; }
    #side .nav li.spacer { display: none; }
    #side > div > div.bottom { height: 10px; }
    #side select { width: 100%; }
    #side button { width: 100%; }
}

@media (min-width: 992px) {
    #side select { width: 100%; }
    #side button { width: 100%; }
}
